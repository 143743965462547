import React from 'react'
import { View, Image } from 'dripsy'
import { Image as ImageType } from '@beatgig/api-services'
import { StyleSheet } from 'react-native'
import { Img, CarouselProps } from './types'
import type { SxStyleProp } from '@theme-ui/core'
import Press from '../press'

type Props<I extends Img> = {
  width: number
  image: I
  renderImage?: CarouselProps<I>['renderImage']
  imageSx?: SxStyleProp
  shouldCloseOnTapBackdrop?: boolean
  onRequestClose: () => void
} & React.ComponentProps<typeof View>

export default React.memo(function ImageCarouselItem<
  I extends { url: string; height?: number; width?: number }
>(props: Props<I>) {
  const {
    sx = {},
    image,
    width,
    renderImage,
    imageSx = {},
    shouldCloseOnTapBackdrop = false,
    onRequestClose,
  } = props

  // if (!width) return null

  const _renderImage = () => {
    const {
      ...imageProps
    }: Omit<Parameters<Required<Props<I>>['renderImage']>[0], 'image'> = {
      source: { uri: image.url },
      sx: {
        // ...StyleSheet.absoluteFillObject,
        height: '100%',
        width: '100%',
        maxWidth: image?.width ? image.width * 1.2 : undefined,
        ...imageSx,
      },
      resizeMode: 'contain',
    }

    if (renderImage) return renderImage({ ...imageProps, image })

    return (
      <Press
        sx={{
          ...StyleSheet.absoluteFillObject,
          alignItems: 'center',
        }}
        onPress={shouldCloseOnTapBackdrop ? onRequestClose : undefined}
      >
        <Image {...imageProps} />
      </Press>
    )
  }

  return (
    <View sx={{ ...sx, width, height: '100%', alignItems: 'center' }}>
      {_renderImage()}
    </View>
  )
})
