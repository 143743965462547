import { createContext, useContext } from 'react'

export type AdminContext = {
  // user?: null | {
  //   displayName: string
  // }
  // setUser: React.Dispatch<React.SetStateAction<AdminContext['user']>>
  isAdminApp: boolean
}

export const ADMIN_PERSIST_KEY = 'ADMIN_ARTIST'

export const AdminContext = createContext<AdminContext>({
  // user: null,
  // setUser: () => {
  //   throw new Error(
  //     '[admin-context] tried to use admin context without a provider'
  //   )
  // },
  isAdminApp: false,
})

export const useAdminContext = (): undefined | AdminContext =>
  useContext(AdminContext)
