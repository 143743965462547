import React from 'react'
import { WebHeaderProps } from './types'
import { View, useDripsyTheme } from 'dripsy'
import useWebHeaderHeight from './use-web-header-height'
import { Platform, StyleSheet } from 'react-native'
import { BlurView } from 'expo-blur'
import SmartArtistSearchInput from '@beatgig/search/components/smart-artist-search-input'
import SmartUserAvatar from '@beatgig/auth-components/smart-user-avatar/_index'
import Logo from '../logo'
import { Link, useRouting } from 'expo-next-react-navigation'
import SmartSignUpButton from '@beatgig/auth-components/smart-sign-up-button'
import SmartLinkToBookings from '@beatgig/auth-components/smart-link-to-bookings'
import Sizer from '../sizer'
import WebHeaderAccountMenu from '@beatgig/ui/web-header-account-menu'
import webHeaderAccountMenu from '@beatgig/ui/web-header-account-menu/use-web-header-account-menu-open'
import Ionicons from '@expo/vector-icons/build/Ionicons'
import useMyAccount from '@beatgig/auth-hooks/use-my-account'
import Press from '../press'
import { SHOW_MARKETING_PAGES } from '@beatgig/constants/feature-flags'
import { User } from '@beatgig/api/user'
import useUserNeedsToAddProfileImage from '@beatgig/auth-hooks/use-user-needs-profile-image'
import useConsoleLog from '@beatgig/hooks/use-log'
import { usePressableChild } from '../press/use-pressable-child'
import { ProductsDropdown } from './products-dropdown'

const userAvatarSize = [35, 'medium']

const openWebHeaderNav = () =>
  webHeaderAccountMenu.setGlobalState('isOpen', true)
const closeWebHeaderNav = (e) => {
  webHeaderAccountMenu.setGlobalState('isOpen', false)
  e.preventDefault()
}

export default React.memo(function WebHeader({
  sx,
  shouldNavigateOnFocus,
  disableBlur,
}: WebHeaderProps) {
  const { height, top, zIndex } = useWebHeaderHeight()
  const router = useRouting()
  const [
    isAccountMenuOpen,
    setAccountMenuIsOpen,
  ] = webHeaderAccountMenu.useGlobalState('isOpen')
  useConsoleLog('[web-header]', { isAccountMenuOpen })
  const { data: me } = useMyAccount()

  const { colors } = useDripsyTheme().theme

  const prefetch = React.useRef(router.prefetch)
  React.useEffect(() => {
    prefetch.current('/search')
  }, [])

  const leftAndRightFlex = [null, 1]

  const onPressOpenUserNav = isAccountMenuOpen ? undefined : openWebHeaderNav

  let shouldShowBadge = false
  const { needsProfileImage } = useUserNeedsToAddProfileImage({
    user: me || null,
  })

  if (User.needsToOnboard(me)) {
    shouldShowBadge = true
  } else if (needsProfileImage) {
    shouldShowBadge = true
  }

  const pressableChild = usePressableChild(
    ({ hovered, focused, pressed }) => (
      <View
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
          bg: hovered || pressed ? 'text' : 'muted',
          transitionDuration: '250ms',
          transitionProperty: 'background-color',
          borderColor: 'text',
          borderRadius: 'rounded',
          py: 1,
          px: 2,
          borderWidth: 1,
          transitionTimingFunction: 'ease-in-out',
          minHeight: 38,
          minWidth: 42,
        }}
      >
        <Ionicons
          name="ios-menu"
          size={24}
          color={colors?.background}
          style={{
            // @ts-ignore
            transitionDuration: '250ms',
            transitionProperty: 'color',
            color: hovered || pressed ? colors?.background : colors?.text,
          }}
        />
        <Sizer display={['none', 'none', 'flex']}>
          <SmartUserAvatar
            shouldShowWhenUnauthenticated={false}
            // onPress={onPressOpenUserNav}
            size={'small'}
            bg="cyan"
            disabled={false}
            Container={View}
            sx={{ ml: 2 }}
            // textSx={{ color: 'text' }}
          />
        </Sizer>
      </View>
    ),
    [colors?.background, colors?.text]
  )

  return (
    <View
      sx={{
        zIndex,
        position: Platform.OS === 'web' ? 'fixed' : 'absolute',
        top: 0,
        right: 0,
        left: 0,
        ...sx,
      }}
    >
      <Sizer height={height}>
        <View sx={{ height: top }} />
        {!disableBlur && (
          <BlurView
            style={StyleSheet.absoluteFillObject}
            tint="dark"
            intensity={Platform.select({ web: 80, default: 100 })}
          />
        )}
        <View
          sx={{ flexDirection: 'row', height: '100%', alignItems: 'center' }}
        >
          <Sizer
            {...{
              flex: leftAndRightFlex,
              justifyContent: 'flex-start',
              pl: [2, 3],
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            <Link
              routeName="/"
              web={{
                path: '/',
              }}
              isText={false}
            >
              <Logo size={35} />
            </Link>
          </Sizer>
          <Sizer flex={[2, 1.5, null, 2]}>
            <Sizer
              px={[2, 2, 0]}
              // flex={1}
              width={'100%'}
              alignSelf="center"
              maxWidth={500}
            >
              <SmartArtistSearchInput
                shouldNavigateOnFocus={shouldNavigateOnFocus}
              />
            </Sizer>
          </Sizer>
          <View
            sx={{
              height: '100%',
              justifyContent: 'center',
              pr: [2, 3],
              flex: leftAndRightFlex,
              alignItems: 'flex-end',
            }}
          >
            <View sx={{ flexDirection: 'row', alignItems: 'center' }}>
              {/* <Sizer mr={2} display={['none', null, null, 'flex']}> 
              </Sizer> */}
              {!User.hasOnboarded(me) && SHOW_MARKETING_PAGES && (
                <ProductsDropdown />
              )}
              <Sizer display={['none', null, 'flex']}>
                <SmartLinkToBookings sx={{ mr: 2 }} />
              </Sizer>
              <Sizer display={['none', null, 'flex']}>
                <SmartSignUpButton sx={{ fontSize: 2, mr: 3 }}>
                  Sign Up
                </SmartSignUpButton>
              </Sizer>
              <View
                sx={{ justifyContent: 'center' }}
                pointerEvents={isAccountMenuOpen ? 'none' : 'auto'}
              >
                <>
                  <Press
                    disabled={isAccountMenuOpen}
                    onPress={onPressOpenUserNav}
                    accessibilityRole="spinbutton"
                  >
                    {pressableChild}
                  </Press>
                  <WebHeaderAccountMenu />
                </>
                {shouldShowBadge && (
                  <View
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      height: 15,
                      width: 15,
                      borderRadius: 'rounded',
                      bg: 'accent',
                    }}
                  />
                )}
              </View>
            </View>
          </View>
        </View>
      </Sizer>
    </View>
  )
})
