import React from 'react'
import { View, useDripsyTheme } from 'dripsy'
import type { SxStyleProp } from '@theme-ui/core'
import { StyleSheet } from 'react-native'
import Ionicons from '@expo/vector-icons/build/Ionicons'
import Press from '../press'

type Props = {
  direction: 'right' | 'left'
  onPress: () => void
  sx?: SxStyleProp
} & React.ComponentProps<typeof View>

export default function CarouselArrow(props: Props) {
  const { sx = {}, direction, onPress } = props

  const icon = direction === 'left' ? `chevron-back` : 'chevron-forward'
  const { colors } = useDripsyTheme().theme
  const foregroundColor = colors?.text

  return (
    <View
      sx={{
        ...StyleSheet.absoluteFillObject,
        right: direction === 'left' ? 'auto' : 0,
        left: direction === 'right' ? 'auto' : 0,
        justifyContent: 'center',
        px: [2, 3],
        ...sx,
      }}
      hitSlop={{ top: 10, left: 10, right: 10, bottom: 10 }}
      pointerEvents="box-none"
    >
      <Press onPress={onPress}>
        {({ hovered, pressed }) => (
          <View
            sx={{
              bg: hovered ? `background` : foregroundColor,
              borderColor: 'border',
              borderRadius: 999,
              borderWidth: 1,
              // p: 3,
              height: 40,
              width: 40,
              justifyContent: 'center',
              alignItems: 'center',
              transitionProperty: 'background-color',
              transitionDuration: '0.3s',
            }}
          >
            <Ionicons
              name={icon}
              color={hovered ? foregroundColor : colors?.background}
              size={30}
              style={{
                marginRight: direction === 'left' ? 2 : -2,
                // @ts-ignore
                transitionProperty: 'color',
                transitionDuration: '0.3s',
              }}
            />
          </View>
        )}
      </Press>
    </View>
  )
}
