import React from 'react'
import { QueryClientProvider } from 'react-query'
import { queryClient } from '../client'

export default function QueryProvider({
  children,
}: {
  children: React.ReactNode
}) {
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  )
}
