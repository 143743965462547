// export { default } from '@beatgig/next-pages/_app'
import 'setimmediate'
import 'raf/polyfill'

import ResizeObserver from 'resize-observer-polyfill'

// @ts-ignore
if (!global.setImmediate) global.setImmediate = setTimeout
if (typeof window !== 'undefined' && window) {
  // @ts-ignore
  window.setImmediate = window.setTimeout
  // @ts-ignore
  window.ResizeObserver = ResizeObserver // set the observer globally before importing app
}
// since safari doesn't have smooth scroll
// import smoothscroll from 'smoothscroll-polyfill'
// kick off the polyfill!
if (typeof window !== 'undefined') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  require('smoothscroll-polyfill').polyfill()
}
import useScrollRestoration from '@beatgig/hooks/use-scroll-restoration'
import LogRocket from 'logrocket'
// import FullStory from 'react-fullstory'
// import Drift from 'react-driftjs'

// import 'react-dates/lib/css/_datepicker.css'

import 'mapbox-gl/dist/mapbox-gl.css'
import 'swiper/swiper-bundle.css'

import React from 'react'
import Providers from '@beatgig/providers'
import NextNprogress from 'nextjs-progressbar'
import { useDripsyTheme } from 'dripsy'
import WebHeader from '@beatgig/components/web-header'
import WebFooter from '@beatgig/components/web-footer'
import GlobalSEO from '@beatgig/components/seo/default-seo'
// import { AnimatePresence, motion } from 'framer-motion'
import Router from 'next/router'
import type { AppProps } from 'next/dist/next-server/lib/router/router'
import Head from 'next/head'
// import IntercomChat from '@beatgig/components/intercom'
import dynamic from 'next/dynamic'
const IntercomChat = dynamic(() => import('@beatgig/components/intercom'), {
  ssr: false,
})
import WebBottomTabNavigation from '@beatgig/ui/web-bottom-tab-navigation'
import ReactNativeNextJsScrollRestore from '@beatgig/helpers/use-react-native-web-restore-scroll'

// TODO move this into the component style scope
// booooooooo why is this global
import '@fullcalendar/common/main.css'
import '@fullcalendar/daygrid/main.css'
import 'tippy.js/dist/tippy.css'

import 'mapbox-gl/dist/mapbox-gl.css'
import webHeaderAccountMenu from '@beatgig/ui/web-header-account-menu/use-web-header-account-menu-open'
import SEO from '@beatgig/components/seo'

import type { PageSEOProps } from '@beatgig/seo/core/page-seo'
import useConsoleLog from '@beatgig/hooks/use-log'
import { APP_URL } from '@beatgig/constants'

// if (process.env.NODE_ENV === 'development') {
//   if (typeof window !== 'undefined') {
//     // eslint-disable-next-line @typescript-eslint/no-var-requires
//     const whyDidYouRender = require('@welldone-software/why-did-you-render')
//     whyDidYouRender(React, {
//       trackAllPureComponents: true,
//     })
//   }
// }

function ProgressBar() {
  const { colors } = useDripsyTheme().theme
  return <NextNprogress color={colors?.primary} />
}

function StyleReset() {
  const { colors } = useDripsyTheme().theme
  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `html { background-color: ${colors?.background};}`,
        }}
      />
      <style jsx global>{`
        ::-moz-selection {
          /* Code for Firefox */
          color: ${colors?.text}!important;
          background: ${colors?.accent}!important;
        }

        ::selection {
          color: ${colors?.text}!important;
          background: ${colors?.accent}!important;
        }
      `}</style>
    </>
  )
}

export default function MyApp({ Component, pageProps, router }: AppProps) {
  React.useEffect(() => {
    if (
      process.env.NODE_ENV === 'production' &&
      window.location.host.endsWith(APP_URL)
    ) {
      LogRocket.init('rnpdid/beatgig')
    }
  }, [])

  React.useEffect(() => {
    Router.events.on('routeChangeStart', () => {
      // close the menu on navigating
      webHeaderAccountMenu.setGlobalState('isOpen', false)
    })
  }, [])

  React.useEffect(() => {
    ReactNativeNextJsScrollRestore.initialize()
  }, [])
  return (
    <>
      <GlobalSEO />
      {(pageProps as PageSEOProps)?.PageSEO && <SEO {...pageProps.PageSEO} />}
      <Providers>
        <StyleReset />

        <Component {...pageProps} />
        {!router.pathname.includes('embed/') && (
          <WebHeader disableBlur={router.pathname.startsWith('/search')} />
        )}
        <ProgressBar />
        <WebBottomTabNavigation />
      </Providers>
      <IntercomChat />
    </>
  )
}
