import React from 'react'
// import { ThemeProvider as DripsyProvider } from '@theme-ui/core'
import theme from '@beatgig/theme/theme'
import { DripsyProvider } from 'dripsy'

type Props = {
  children: React.ReactNode
}

export default function Dripsy({ children }: Props) {
  return <DripsyProvider theme={theme as any}>{children}</DripsyProvider>
}
