import { useCallback, ReactNode } from 'react'
import type { PressableStateCallbackType } from 'react-native'
type Factory = (props: PressableStateCallbackType) => ReactNode

export function usePressableChild(
  factory: Factory,
  deps: Parameters<typeof useCallback>[1]
) {
  const memoFactory = useCallback(
    (
      pressed: boolean,
      focused?: boolean,
      hovered?: boolean,
      interacted?: boolean
    ) =>
      factory({
        pressed,
        hovered,
        focused,
        interacted,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps
  )
  return useCallback(
    ({ pressed, focused, interacted, hovered }: PressableStateCallbackType) => {
      // the object gets re-created each render, so we need to pass primitives
      return memoFactory(pressed, focused, hovered, interacted)
    },
    [memoFactory]
  )
}
