import { User } from '@beatgig/api/user'
import { GetUser } from '@beatgig/api-services/user'

type Props = {
  user: GetUser | null
}

export default function useUserNeedsToAddProfileImage({ user }: Props) {
  const needsProfileImage =
    User.isBuyer(user) && User.hasOnboarded(user) && !user.profile_image?.url

  return {
    needsProfileImage,
  }
}
