import { useCallback } from 'react'
import { useRouting } from 'expo-next-react-navigation'
import { AuthedRoutes } from '@beatgig/navigation/routes/authed-routes'
import Analytics from '../analytics/typez'
import { User } from '@beatgig/api/user'
import searchState, {
  initialGlobalSearchState,
} from '@beatgig/search/hooks/use-global-state'
import firebase from 'firebase/app'

const signOut = () => firebase.auth().signOut()

export default function useSignOut() {
  // const [, signOut] = useMaybeDoormanUser()
  const { navigate, pathname } = useRouting()

  return useCallback(async () => {
    Analytics.track('Sign Out')
    if (pathname?.includes(AuthedRoutes.dashboard().routeName)) {
      navigate({
        routeName: 'Discover',
        web: {
          path: '/',
        },
      })
    }
    searchState.setGlobalState('price', initialGlobalSearchState.price)
    await User.removeFromAsyncStorage()
    await signOut()
  }, [pathname, navigate])
}
