import React from 'react'
import { View, Text } from 'dripsy'
import Link from '@beatgig/design/components/link'
import useMyAccount from '@beatgig/auth-hooks/use-my-account'
import LinkToDashboard from '@beatgig/navigation/links/link-to-dashboard'
import { User } from '@beatgig/api/user'
import { useRouting } from 'expo-next-react-navigation'
import { AuthedRoutes } from '@beatgig/navigation/routes/authed-routes'

type Props = Omit<React.ComponentProps<typeof Link>, 'onPress' | 'children'> & {
  children?: React.ReactNode
  params?: Parameters<typeof AuthedRoutes['calendar']>[0]
}

export default function SmartLinkToCalendar({
  children = 'Calendar',
  params,
  ...props
}: Props) {
  const { data } = useMyAccount()
  const { navigate } = useRouting()

  if (!User.hasOnboarded(data) || !User.isMultiVenueBuyer(data)) return null

  const onPress = () => {
    navigate(AuthedRoutes.calendar(params))
  }

  return (
    <Link block {...props} onPress={onPress}>
      {children}
    </Link>
  )
}
