import React from 'react'
import { View, useDripsyTheme, Text } from 'dripsy'
import Ionicons from '@expo/vector-icons/build/Ionicons'
import { DripsyTheme } from '@beatgig/theme'
import Press from '@beatgig/components/press'
import Grid from '@beatgig/design/components/grid'

type Props = {
  onPress: () => void
  iconName: React.ComponentProps<typeof Ionicons>['name']
  isActive: boolean
  label: string
}

const TabItem = React.memo(function TabItem(props: Props) {
  const { iconName = 'ios-add', onPress, isActive, label, ...gridProps } = props

  const { colors } = (useDripsyTheme().theme as any) as DripsyTheme

  const color = isActive ? 'primary' : 'text'

  return (
    <Grid.Item {...gridProps}>
      <Press onPress={onPress} sx={{ flex: 1, alignItems: 'center' }}>
        <View sx={{ my: 1 }}>
          <View sx={{ alignItems: 'center' }}>
            <Ionicons name={iconName} size={25} color={colors?.[color]} />
          </View>
          <View>
            <Text sx={{ textAlign: 'center', fontSize: 1, color }}>
              {label}
            </Text>
          </View>
        </View>
      </Press>
    </Grid.Item>
  )
})

export default TabItem
