import React, { useState, useCallback } from 'react'
import WebHeaderMenu from '../web-header-menu'
import webHeaderAccountMenu from './use-web-header-account-menu-open'
import { Text, useDripsyTheme, View } from 'dripsy'
import Ionicons from '@beatgig/design/components/ionicons'
import useMyAccount from '@beatgig/auth-hooks/use-my-account'
import { User } from '@beatgig/api/user'
import useSignOut from '@beatgig/auth-hooks/use-sign-out'
import { useRouting } from 'expo-next-react-navigation'
import { AuthedRoutes } from '@beatgig/navigation/routes/authed-routes'
import useOpenArtistLikesPopup from '../artist-likes-popup/use-open-artist-likes-popup'
import Button from '@beatgig/components/button'
import useIntercom from '@beatgig/hooks/use-intercom'
import useAuth from '@beatgig/auth-hooks/use-auth'
import Link from '@beatgig/design/components/link'
import PublicRoutes from '@beatgig/navigation/routes/public-routes'
import useOpenAuthPopup from '@beatgig/auth-hooks/use-open-auth-popup'
import { BAR_PRODUCT_FEATURE_FLAG_HIDDEN } from '@beatgig/constants/feature-flags'
import useUserPrivateReferralInfo from '@beatgig/api-hooks/src/use-user-private-referral-info'
import useResponsive from '@beatgig/hooks/use-responsive'
import Badge from '@beatgig/design/components/badge'
import useUserNeedsToAddProfileImage from '@beatgig/auth-hooks/use-user-needs-profile-image'
import { useProductsDropdown } from '@beatgig/components/web-header/products-dropdown/use-products-dropdown'
import Description from '@beatgig/design/components/description'

const routes = {
  venues: AuthedRoutes.venues(),
  bookings: AuthedRoutes.bookings(),
  calendar: AuthedRoutes.calendar(),
  account: AuthedRoutes.account(),
  inbox: AuthedRoutes.venueApplicationsInbox(),
  artists: AuthedRoutes.artists(),
  events: PublicRoutes.events(),
  refer: AuthedRoutes.refer(),
  products: {
    colleges: PublicRoutes.marketingPage('colleges'),
    artists: PublicRoutes.marketingPage('artists'),
    venues: PublicRoutes.marketingPage('venues'),
  },
}

const WebHeaderAccountMenu = React.memo(function WebHeaderAccountMenu() {
  const [
    isAccountMenuOpen,
    setAccountMenuIsOpen,
  ] = webHeaderAccountMenu.useGlobalState('isOpen')

  const productsDropdown = useProductsDropdown()

  const { navigate } = useRouting()
  const openLikes = useOpenArtistLikesPopup()

  const { user } = useAuth()

  const { data: me } = useMyAccount()
  const signOut = useSignOut()
  const [isSigningOut, setIsSigningOut] = useState(false)

  const openAuth = useOpenAuthPopup()

  const onPressOpenAuth = useCallback(() => {
    setAccountMenuIsOpen(false)
    openAuth()
  }, [openAuth, setAccountMenuIsOpen])

  const onPressOpenMyArtists = useCallback(() => {
    navigate(AuthedRoutes.artists())
  }, [navigate])

  const onPressSignOut = useCallback(async () => {
    if (isSigningOut) return

    setAccountMenuIsOpen(false)

    setIsSigningOut(true)
    signOut()
    setIsSigningOut(false)
  }, [isSigningOut, setAccountMenuIsOpen, signOut])

  const makeNavigate = useCallback(
    (...params: Parameters<typeof navigate>) => {
      return () => {
        navigate(...params)
      }
    },
    [navigate]
  )

  const onPressLikes = useCallback(() => {
    openLikes()
    setAccountMenuIsOpen(false)
  }, [openLikes, setAccountMenuIsOpen])

  const intercom = useIntercom()

  const { colors } = useDripsyTheme().theme

  const iconColor = colors?.text
  const iconSize = 25

  const isAdmin = User.isAdmin(me)
  const { data: privateReferralInfo } = useUserPrivateReferralInfo(
    User.hasOnboarded(me) && me.id ? { userId: me.id } : null
  )

  const { vh } = useResponsive()

  const canReferUsers = !!privateReferralInfo?.can_i_refer
  // const needsProfileImage = User.hasOnboarded(me) && !me.profile_image?.url
  const { needsProfileImage } = useUserNeedsToAddProfileImage({
    user: me || null,
  })

  return (
    <WebHeaderMenu
      isOpen={isAccountMenuOpen}
      onRequestClose={() => setAccountMenuIsOpen(false)}
      header={false}
      maxHeight={vh(90)}
    >
      {!BAR_PRODUCT_FEATURE_FLAG_HIDDEN &&
        (User.isMultiVenueBuyer(me) || isAdmin) && (
          <WebHeaderMenu.Item
            prefix={
              <Ionicons color={iconColor} name="ios-calendar" size={iconSize} />
            }
            onPress={makeNavigate(routes.calendar)}
          >
            Calendar
          </WebHeaderMenu.Item>
        )}
      {(User.hasOnboarded(me) || isAdmin) && (
        <WebHeaderMenu.Item
          prefix={
            <Ionicons
              // sx={{ marginRight: 3.01 }}
              color={iconColor}
              name="layers-sharp"
              size={iconSize}
            />
          }
          onPress={makeNavigate(routes.bookings)}
        >
          Bookings
        </WebHeaderMenu.Item>
      )}
      {(User.isMultiVenueBuyer(me) || isAdmin) && (
        <WebHeaderMenu.Item
          prefix={
            <Ionicons color={iconColor} name="ios-mail" size={iconSize} />
          }
          onPress={makeNavigate(routes.inbox)}
        >
          Inbox
        </WebHeaderMenu.Item>
      )}

      {User.hasOnboarded(me) && <WebHeaderMenu.Divider />}
      {!productsDropdown.isDropdownInHeader && !User.hasOnboarded(me) && (
        <>
          <WebHeaderMenu.Item sx={{ pb: 0, mb: 0 }}>
            <Description title="Our Products"></Description>
          </WebHeaderMenu.Item>
          <WebHeaderMenu.Item
            prefix={
              <Ionicons color={iconColor} name="school" size={iconSize} />
            }
            onPress={makeNavigate(routes.products.colleges)}
          >
            Colleges
          </WebHeaderMenu.Item>
          <WebHeaderMenu.Item
            prefix={
              <Ionicons color={iconColor} name="business" size={iconSize} />
            }
            onPress={makeNavigate(routes.products.venues)}
          >
            Venues
          </WebHeaderMenu.Item>
          <WebHeaderMenu.Item
            prefix={
              <Ionicons color={iconColor} name="musical-note" size={iconSize} />
            }
            onPress={makeNavigate(routes.products.artists)}
          >
            Artists
          </WebHeaderMenu.Item>
          <WebHeaderMenu.Divider />
        </>
      )}
      {
        <WebHeaderMenu.Item
          onPress={makeNavigate(routes.events)}
          prefix={<Ionicons color={iconColor} name="ios-map" size={iconSize} />}
        >
          Discover Events
        </WebHeaderMenu.Item>
      }

      {User.isSeller(me) && (
        <>
          <WebHeaderMenu.Divider />
          <WebHeaderMenu.Item
            prefix={
              <Ionicons
                color={iconColor}
                name="ios-musical-notes"
                size={iconSize}
              />
            }
            onPress={onPressOpenMyArtists}
            isText
          >
            My Artist {User.isMultiArtistSeller(me) ? 'Profiles' : 'Profile'}
          </WebHeaderMenu.Item>
        </>
      )}

      {User.hasOnboarded(me) && (
        <>
          <WebHeaderMenu.Divider />
          <WebHeaderMenu.Item
            prefix={
              <Ionicons
                color={iconColor}
                name="heart-outline"
                size={iconSize}
              />
            }
            onPress={onPressLikes}
          >
            Liked Artists
          </WebHeaderMenu.Item>
        </>
      )}
      {User.isMultiVenueBuyer(me) && (
        <>
          <WebHeaderMenu.Divider />
          <WebHeaderMenu.Item
            prefix={
              <Ionicons color={iconColor} name="ios-business" size={iconSize} />
            }
            onPress={makeNavigate(routes.venues)}
          >
            My Venues
          </WebHeaderMenu.Item>
        </>
      )}
      <WebHeaderMenu.Divider />
      {User.hasOnboarded(me) && (
        <>
          {canReferUsers && (
            <>
              <WebHeaderMenu.Item
                prefix={
                  <Ionicons
                    name="gift-outline"
                    size={iconSize}
                    color={'success'}
                  />
                }
                onPress={makeNavigate(routes.refer)}
                color="success"
              >
                Rewards
              </WebHeaderMenu.Item>
              <WebHeaderMenu.Divider />
            </>
          )}
          <WebHeaderMenu.Item
            prefix={
              <Ionicons
                color={iconColor}
                name="person-circle"
                size={iconSize}
              />
            }
            onPress={makeNavigate(routes.account)}
            // sx={{ mb: 0 }}
            suffix={
              needsProfileImage && (
                <View
                  sx={{
                    height: 15,
                    width: 15,
                    borderRadius: 'rounded',
                    bg: 'accent',
                  }}
                />
              )
            }
          >
            My Account
          </WebHeaderMenu.Item>
        </>
      )}
      {User.needsToOnboard(me) && (
        <>
          <WebHeaderMenu.Item hoveredColor="transparent">
            <Button
              sx={{ bg: 'accent', color: 'text', borderColor: 'accent' }}
              onPress={onPressOpenAuth}
            >
              Complete Sign Up
            </Button>
          </WebHeaderMenu.Item>
          <WebHeaderMenu.Divider />
        </>
      )}
      {!user && (
        <>
          <WebHeaderMenu.Item hoveredColor="transparent">
            <View
              sx={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Link onPress={onPressOpenAuth}>Log In</Link>
              <Button
                variant="smallOutlined"
                sx={{ color: 'primary' }}
                onPress={onPressOpenAuth}
              >
                Sign Up
              </Button>
            </View>
          </WebHeaderMenu.Item>
          <WebHeaderMenu.Divider />
        </>
      )}
      <WebHeaderMenu.Item onPress={onPressSignOut} hoveredColor="transparent">
        <View
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Button
            touchableProps={{ sx: { flex: !user ? 1 : undefined } }}
            onPress={intercom.show}
          >
            Contact Support
          </Button>
          {!!user && (
            <Link onPress={onPressSignOut} color="error" block>
              {isSigningOut ? 'Signing Out...' : 'Sign Out'}
            </Link>
          )}
        </View>
      </WebHeaderMenu.Item>
    </WebHeaderMenu>
  )
})

export default WebHeaderAccountMenu
