import Tabs from '@beatgig/design/components/tabs'
import useMyAccount from './use-my-account'
import { User } from '@beatgig/api/user'
import { useRouting } from 'expo-next-react-navigation'
import { AuthedRoutes } from '../navigation/routes/authed-routes'
import { useMemo } from 'react'
import { BAR_PRODUCT_FEATURE_FLAG_HIDDEN } from '@beatgig/constants/feature-flags'
import type Ionicons from '@expo/vector-icons/build/Ionicons'
import useUserPrivateReferralInfo from '@beatgig/api-hooks/src/use-user-private-referral-info'

type AuthenticatedTab = React.ComponentProps<typeof Tabs>['tabs'][0] & {
  /**
   * Function that receives its own value and the router `pathname` and returns `true` if it's active.
   *
   * By default, it checks if the `value` is included in the pathname
   */
  isActive?: (value: string, pathname: string) => boolean
  onPress: () => void
  iconName: React.ComponentProps<typeof Ionicons>['name']
  hideFromTabBar?: boolean
}

/**
 * Determines if the route in question is currently active
 *
 * @param value the value of this route: (ex: `dashboard`)
 * @param pathname the current URL pathname (ex: `/dashboard`)
 */
const isActive: Required<AuthenticatedTab>['isActive'] = (value, pathname) =>
  pathname === `/${value}` ||
  `/dashboard/${value}` === pathname ||
  pathname === value

export default function useAuthenticatedPages() {
  const { data: user } = useMyAccount()

  const { data: privateReferralInfo } = useUserPrivateReferralInfo(
    User.hasOnboarded(user) && user.id
      ? {
          userId: user.id,
        }
      : null
  )

  const canReferUsers = !!privateReferralInfo?.can_i_refer

  const { navigate } = useRouting()

  const pages = useMemo(() => {
    const pages: AuthenticatedTab[] = []
    if (User.hasOnboarded(user)) {
      if (User.isAdmin(user) || User.isMultiVenueBuyer(user)) {
        pages.push({
          title: 'Calendar',
          value: AuthedRoutes.calendar().routeName,
          isActive,
          onPress: () => {
            navigate(AuthedRoutes.calendar())
          },
          iconName: 'ios-calendar',
        })
      }
      pages.push({
        title: 'Bookings',
        value: AuthedRoutes.bookings().routeName,
        isActive: (value, pathname) => {
          return (
            isActive(value, pathname) ||
            // since /dashboard defaults to bookings, /dashboard could as active
            isActive(
              AuthedRoutes.bookings().web.path.split('/').filter(Boolean)[0],
              pathname
            ) ||
            // and we also want it active for /dashboard/bookings/ID
            pathname.includes(AuthedRoutes.bookings().web.path)
          )
        },
        onPress: () => {
          navigate(AuthedRoutes.bookings())
        },
        iconName: 'ios-musical-notes',
      })
    }
    if (User.isSeller(user)) {
      pages.push({
        title: 'My ' + (User.isMultiArtistSeller(user) ? 'Artists' : 'Artist'),
        value: AuthedRoutes.artists().routeName,
        isActive,
        onPress: () => {
          navigate(AuthedRoutes.artists())
        },
        iconName: 'musical-note-outline',
      })
    }
    if (User.isAdmin(user) || User.isMultiVenueBuyer(user)) {
      pages.push({
        title: 'Inbox',
        value: AuthedRoutes.venueApplicationsInbox().routeName,
        isActive,
        onPress: () => {
          navigate(AuthedRoutes.venueApplicationsInbox())
        },
        iconName: 'ios-mail',
        hideFromTabBar: true,
      })
    }
    if (User.isAdmin(user) || User.isSeller(user)) {
      pages.push({
        title: 'Sent',
        value: AuthedRoutes.venueApplicationsOutbox().routeName,
        isActive,
        onPress: () => {
          navigate(AuthedRoutes.venueApplicationsOutbox())
        },
        iconName: 'ios-mail',
      })
    }
    if (User.hasOnboarded(user)) {
      pages.push({
        title: 'Likes',
        value: AuthedRoutes.likes().routeName,
        isActive,
        onPress: () => {
          navigate(AuthedRoutes.likes())
        },
        iconName: 'ios-heart',
        hideFromTabBar: true,
      })
    }

    if (User.isMultiVenueBuyer(user)) {
      pages.push({
        title: 'Venues',
        value: AuthedRoutes.venues().routeName,
        isActive,
        onPress: () => {
          navigate(AuthedRoutes.venues())
        },
        iconName: 'ios-business',
      })
      // pages.push({
      //   title: 'Magic Booker',
      //   value: AuthedRoutes.automatedBookings().routeName,
      //   isActive: (value, pathname) => {
      //     return pathname.includes(AuthedRoutes.automatedBookings().web.path)
      //   },
      //   onPress: () => {
      //     navigate(AuthedRoutes.automatedBookings())
      //   },
      // })
    }
    if (canReferUsers) {
      pages.push({
        // title: User.isBuyer(user) ? 'Free Bookings' : 'Refer',
        title: 'Rewards',
        value: AuthedRoutes.refer().routeName,
        isActive,
        onPress: () => {
          navigate(AuthedRoutes.refer())
        },
        iconName: 'medal-outline',
      })
    }
    if (User.hasOnboarded(user)) {
      pages.push({
        title: 'Account',
        value: AuthedRoutes.account().routeName,
        isActive,
        onPress: () => {
          navigate(AuthedRoutes.account())
        },
        iconName: 'person-circle-outline',
        hideFromTabBar: true,
      })
    }
    return pages
  }, [canReferUsers, navigate, user])

  return {
    pages,
  }
}
