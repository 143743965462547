import { createGlobalState } from 'react-hooks-global-state'

const initialState = { isOpen: false }
const { useGlobalState, setGlobalState } = createGlobalState(initialState)

export default function useArtistLikesPopupState() {
  return useGlobalState('isOpen')
}

useArtistLikesPopupState.set = setGlobalState
