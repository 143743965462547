import React, { useEffect, useState, useRef } from 'react'
import { View, useDripsyTheme, Text, ScrollView } from 'dripsy'
import { Platform, StyleSheet } from 'react-native'
import Press from '@beatgig/components/press'
import Ionicons from '@expo/vector-icons/build/Ionicons'
import { DripsyTheme } from '@beatgig/theme'
import type { ThemeUICSSProperties } from '@theme-ui/css'
import { BlurView } from 'expo-blur'
import useClickAway from '@beatgig/hooks/use-click-away'

type Props = {
  isOpen?: boolean
  onRequestClose?: () => void
  onDidCloseComplete?: () => void
  width?: ThemeUICSSProperties['width']
  bg?: ThemeUICSSProperties['bg'] | keyof DripsyTheme['colors']
  height?: ThemeUICSSProperties['height']
  maxHeight?: ThemeUICSSProperties['maxHeight']
  title?: string
  header?: boolean
  children?: React.ReactNode
}

const clickawayEvents = ['mousedown', 'click']

export default function WebHeaderMenu(props: Props) {
  const {
    isOpen = false,
    onRequestClose,
    onDidCloseComplete,
    width = 300,
    height = 'auto',
    title,
    header = true,
    children,
    maxHeight,
  } = props

  // const [controlledOpen] = useState(isOpen)

  const controlledOpen = isOpen

  const { colors } = (useDripsyTheme().theme as any) as DripsyTheme

  const ref = useRef(null)

  const onAttemptToClose = () => {
    if (isOpen) onRequestClose?.()
    // setControlledOpen(false)
  }

  useClickAway(ref, onAttemptToClose, clickawayEvents)

  useEffect(
    function updateControlledStateFromProps() {
      if (typeof isOpen !== 'undefined') {
        // setControlledOpen(isOpen)
      }
    },
    [isOpen]
  )

  if (Platform.OS !== 'web') return null

  return (
    <View
      sx={{
        position: 'absolute',
        top: '100%',
        right: 0,
        mt: 1,
      }}
      ref={ref}
      // @ts-ignore
      pointerEvents={controlledOpen ? 'auto' : 'none'}
    >
      <View
        sx={{
          transform: [
            {
              scale: controlledOpen ? 1 : 0.95,
            },
          ],
          opacity: controlledOpen ? 1 : 0,
          // @ts-ignore
          transformOrigin: 'top right',
          transitionProperty: 'transform,opacity,-webkit-transform',
          transitionDuration: '250ms',
        }}
      >
        <View
          sx={{
            width,
            maxWidth: '100vh',
            height,
            // bg: 'text',
            borderRadius: 4,
            borderWidth: 1,
            borderColor: 'muted6',
            overflow: 'hidden',
            maxHeight,
          }}
        >
          <BlurView
            style={StyleSheet.absoluteFillObject}
            tint="dark"
            intensity={Platform.select({ web: 100, default: 100 })}
          />
          {header && (
            <View
              sx={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <View
                sx={{
                  px: 3,
                }}
              >
                <Text variant="caps" sx={{ color: 'muted7' }}>
                  {title}
                </Text>
              </View>
              <View sx={{ justifyContent: 'center' }}>
                <Press
                  sx={{
                    px: 3,
                    py: 2,
                    cursor: controlledOpen ? 'pointer' : 'default',
                  }}
                  onPress={onAttemptToClose}
                >
                  <Ionicons
                    name="ios-close"
                    size={30}
                    color={colors['muted7']}
                  />
                </Press>
              </View>
            </View>
          )}
          <ScrollView accessibilityRole="menu" showsVerticalScrollIndicator>
            {children}
          </ScrollView>
        </View>
      </View>
    </View>
  )
}

const Div = <View sx={{ height: 1, bg: 'muted6' }} />

WebHeaderMenu.Divider = function WebHeaderMenuDivider() {
  return Div
}

WebHeaderMenu.Item = function WebHeaderMenuItem({
  children,
  suffix,
  prefix,
  sx,
  onPress,
  hoveredColor,
  isText = typeof children === 'string',
  color = 'text',
}: {
  children: React.ReactNode
  suffix?: React.ReactNode
  prefix?: React.ReactNode
  sx?: ThemeUICSSProperties
  onPress?: () => void
  hoveredColor?: string
  isText?: boolean
  color?: keyof DripsyTheme['colors']
}) {
  const Container = isText ? Text : View

  const { colors } = (useDripsyTheme().theme as any) as DripsyTheme

  return (
    <Press accessibilityRole="menuitem" onPress={onPress}>
      {({ hovered, focused, pressed }) => (
        <View
          sx={{
            // @ts-ignore
            transitionProperty: 'background-color',
            transitionDuration: '250ms',
            backgroundColor:
              hovered || focused || pressed
                ? hoveredColor || `${colors?.text}50`
                : 'transparent',
            flexDirection: 'row',
            alignItems: 'center',
            px: 3,
            py: 2,
            my: 2,
            ...sx,
          }}
        >
          {!!prefix && (
            <View sx={{ mr: 2, width: 25, alignItems: 'center' }}>
              {prefix}
            </View>
          )}
          <View sx={{ flex: 1 }}>
            <Container
              sx={
                isText
                  ? {
                      color,
                      fontSize: 3,
                    }
                  : {}
              }
            >
              {children}
            </Container>
          </View>
          {!!suffix && (
            <View sx={{ ml: 2, width: 25, alignItems: 'center' }}>
              {suffix}
            </View>
          )}
        </View>
      )}
    </Press>
  )
}
