import { ReferralApi } from '@beatgig/api/referral'
import useGet from './use-get'
import { UseGetConfig } from './use-get.types'

const getter = ReferralApi.getPrivateReferralInfo
const keyMaker = ReferralApi.privateReferralInfoSWRKey

type Getter = typeof getter
type KeyMaker = typeof keyMaker

export default function useUserPrivateReferralInfo(
  keyProps: Parameters<KeyMaker>[0] | null,
  config?: UseGetConfig<Getter>
) {
  return useGet(
    () => {
      if (!keyProps?.userId) return null

      return keyMaker(keyProps)
    },
    (...[adminOnBehalfOf]: ReturnType<KeyMaker>) => {
      return getter({
        adminOnBehalfOf,
      })
    },
    config
  )
}
