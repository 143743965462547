import React from 'react'
import { DefaultSeo } from 'next-seo'
import { APP_NAME, APP_URL } from '@beatgig/constants'

export default function GlobalSEO() {
  return (
    <DefaultSeo
      // titleTemplate={`%s · ${APP_NAME}`}
      title={`Book Talent on ${APP_NAME}`}
      openGraph={{
        type: 'website',
        locale: 'en_IE',
        url: `https://${APP_URL}/`,
        site_name: `${APP_NAME} · Book Talent`,
        images: [
          {
            url: `https://${APP_URL}/` + 'og2.png',
            alt: `${APP_NAME} · Book Talent`,
            height: 1200,
            width: 630,
          },
        ],
      }}
      twitter={{
        handle: '@beatgig',
        site: '@beatgig',
        cardType: 'summary_large_image',
      }}
    />
  )
}
