// import { OpenAPI } from '@beatgig/api-services'
import { UsersService } from '@beatgig/api-services/user'
import { OpenAPI as OpenAPIPackage } from '@beatgig/bg-production-live'

export class AuthApi {
  static setEndpointBase(base: string) {
    OpenAPIPackage.BASE = base
  }
  static get hasTokenSet() {
    return !!OpenAPIPackage.TOKEN
  }
  /**
   * This function sets the token that gets used whenever the api-services call the backend.
   *
   * Since @beatgig/api-serivces are generated by the server and we can't edit the requests,
   * this function exposes a way to edit the `Bearer` used by those requests.
   *
   * It should be called whenever the auth token updates.
   *
   * @param token - Firebase auth token, or a function which returns it
   */
  static setApiServicesToken(token: string | null | (() => Promise<string>)) {
    const setToken = token ?? ''

    // OpenAPI.TOKEN = setToken
    OpenAPIPackage.TOKEN = setToken
  }
  static recordUserLogin = () => {
    return UsersService.login()
  }
  static get firebaseConfig() {
    if (process.env.NEXT_PUBLIC_BACKEND_ENV === 'sandbox') {
      return {
        apiKey: 'AIzaSyAqa7593kHdsRoJYVy5WKpVDVgVEu6aWa0',
        authDomain: 'beatgig-sandbox.firebaseapp.com',
        projectId: 'beatgig-sandbox',
        storageBucket: 'beatgig-sandbox.appspot.com',
        messagingSenderId: '1087216282988',
        appId: '1:1087216282988:web:1601b351ef5b9f921ed749',
        measurementId: 'G-LH4Z8GCLE7',
      }
    }
    return {
      apiKey: 'AIzaSyAb_W-FjczayZhjXTk9kd_3BPO07N_DU6M',
      authDomain: 'beatgigtest.firebaseapp.com',
      databaseURL: 'https://beatgigtest.firebaseio.com',
      projectId: 'beatgigtest',
      storageBucket: 'beatgigtest.appspot.com',
      messagingSenderId: '676970844475',
      appId: '1:676970844475:web:f638f3e750f0749ab6671e',
      measurementId: 'G-777YYV0YVX',
    }
  }
  static get doormanPublicProjectId() {
    if (process.env.NEXT_PUBLIC_BACKEND_ENV === 'sandbox') {
      return 'cIUvJ8eI2gAMH9Ox583z'
    }

    return 'CCTbUWjssSQJeavPgRMW'
  }
}
