import React, { useCallback, useRef, useEffect } from 'react'
import SearchInput from '@beatgig/components/search-input'
import { useRouting } from 'expo-next-react-navigation'
import { Platform, TextInput } from 'react-native'
import useGlobalArtistSearchQueryState from '../hooks/use-global-search-state'
import searchState from '../hooks/use-global-state'
import Router from 'next/router'
import useWhyDidYouUpdate from '@beatgig/hooks/use-why-did-you-update'
import easyLog from '@beatgig/helpers/make-log'

type Props = {
  shouldNavigateOnFocus?: boolean
}

const onChangeText = (text: string) => searchState.setGlobalState('query', text)

const onBlur = easyLog('search-input', 'blurred...')

export default React.memo(function SmartArtistSearchInput({
  shouldNavigateOnFocus = true,
}: Props) {
  const { canGoBack, pathname, goBack } = useRouting()
  const [query, setQuery] = useGlobalArtistSearchQueryState()
  const isOnSearchScreen = pathname?.startsWith('/search')

  // const willNavigateOnFocus =
  //   shouldNavigateOnFocus && !isOnSearchScreen && Platform.OS === 'web'

  // useWhyDidYouUpdate('smart-artist-search-input', {
  //   willNavigateOnFocus,
  // })

  const inputRef = useRef<TextInput>(null)

  useEffect(
    function focusInputOnSearchScreen() {
      // for some reason, the input gets blurred when we navigate
      // this monkey patch fixes that by imperatively focusing on this screen
      // 🐒
      if (isOnSearchScreen && !inputRef.current?.isFocused()) {
        inputRef.current?.focus()
      }
    },
    [isOnSearchScreen]
  )

  const onFocus = useCallback(() => {
    const isOnSearchScreen = Router.pathname.startsWith('/search')
    if (Platform.OS === 'web' && !isOnSearchScreen) {
      if (shouldNavigateOnFocus) {
        setTimeout(() => {
          Router.push('/search')
          // navigate({
          //   routeName: 'search',
          // })
        }, 10)
      }
    }
  }, [shouldNavigateOnFocus])

  const onClear = useCallback(() => {
    // setQuery('')
    onChangeText('')

    // if (canGoBack() && Platform.OS === 'web' && Router.pathname.startsWith('/search')) {
    //   // goBack()
    // }
  }, [])

  const onGoBack = useCallback(() => {
    if (canGoBack()) {
      goBack()
    }
  }, [canGoBack, goBack])

  return (
    <SearchInput
      onFocus={onFocus}
      inputRef={inputRef}
      value={query}
      onChangeText={setQuery}
      // onBlur={onBlur}
      // onChangeText={onChangeText}
      showBack={canGoBack() && isOnSearchScreen}
      onClear={onClear}
      onGoBack={onGoBack}
      // autoFocus={isOnSearchScreen}
    />
  )
})
