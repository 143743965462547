import React from 'react'
import { Modal, Platform } from 'react-native'
import useImageCarouselPopup from './use-image-carousel-popup'
import ImageCarousel from '@beatgig/components/image-carousel'

export default function ImageCarouselPopup() {
  const [{ isOpen, props }, { close }] = useImageCarouselPopup()

  return (
    <Modal
      transparent
      animationType={Platform.select({ web: 'fade', default: 'slide' })}
      visible={isOpen}
      onRequestClose={close}
    >
      <ImageCarousel {...props} onRequestClose={close} />
    </Modal>
  )
}
