import React from 'react'
import { DoormanProvider } from 'react-doorman'
import { cache } from 'swr'
import firebase from 'firebase/app'
// import 'firebase/auth'
import { AuthApi } from '@beatgig/api/auth'
import Analytics from '@beatgig/analytics/typez'
import { Platform } from 'react-native'
import { User } from '@beatgig/api/user'
import { INTERCOM_ID } from '@beatgig/components/intercom/constants'
import { updateAlgoliaUserTokenOnAuthStateChanged } from '@beatgig/search/client'
import globalSelectedVenueState from '@beatgig/helpers/global-selected-venue-state'
import vercelConstants from '@beatgig/constants/vercel-constants'
import NextRouter from 'next/router'
import { useAdminContext } from '../Admin/context'
// import useOpenAuthPopup from '@beatgig/auth-hooks/use-open-auth-popup'
// import { User } from '@beatgig/api/user'

// 1. initialize Firebase
if (
  !firebase.apps.length &&
  (Platform.OS !== 'web' || typeof window !== 'undefined')
) {
  require('firebase/auth')
  firebase.initializeApp(AuthApi.firebaseConfig)
}

type Props = {
  children: React.ReactNode
}

export default function Doorman(props: Props) {
  const { children } = props

  const isAdminApp = useAdminContext()?.isAdminApp

  // React.useEffect(() => {
  //   firebase.auth().onIdTokenChanged(console.log)
  // }, [])

  // return <>{children}</>

  // return (
  //   <DoormanProvider
  //     initialPhoneNumber={Platform.select({ web: '', default: undefined })}
  //     // onAuthStateChanged={onAuthStateChanged}
  //     publicProjectId="CCTbUWjssSQJeavPgRMW"
  //   >
  //     {children}
  //   </DoormanProvider>
  // )

  // const { pathname } = useRouting()
  // const intercomIcon = useShouldShowIntercomIcon()

  // const open = useOpenAuthPopup()

  const onAuthStateChanged = React.useCallback(
    async (user: firebase.User | null) => {
      const SentryPromise = import('@beatgig/helpers/sentry')
      if (user) {
        AuthApi.setApiServicesToken(async () => user.getIdToken(false))
        SentryPromise.then(({ Sentry }) =>
          Sentry.setUser({
            id: user.uid,
            name: user.displayName ?? '',
            phone_number: user.phoneNumber ?? '',
            email: user.email ?? '',
          })
        )
        Analytics.identify({
          uid: user.uid,
          name: user.displayName ?? '',
          phone_number: user.phoneNumber ?? '',
          email: user.email ?? '',
        })
        AuthApi.recordUserLogin()

        if (Platform.OS === 'web') {
          const { pathname } = NextRouter
          const showIntercom =
            pathname && !pathname?.includes('embed') && !isAdminApp
          console.log('[doorman-provider]', { pathname, showIntercom })
          if (process.env.NODE_ENV === 'production') {
            import('logrocket')
              .then((rocket) => rocket.default)
              .then((LogRocket) => {
                LogRocket.identify(user.uid, {
                  name: user.displayName ?? '',
                  phone_number: user.phoneNumber ?? '',
                  email: user.email ?? '',
                  ...(vercelConstants() as {}),
                })
              })
          }

          if (showIntercom) {
            // TODO this is commented out for now, re-add later.
            window.Intercom?.('update', {
              app_id: INTERCOM_ID,
              user_id: user.uid,
              // phone_number: user.phoneNumber ?? '',
              name: user?.displayName ?? '',
              email: user.email ?? '',
              ...vercelConstants(),
            })
          }
        }
        updateAlgoliaUserTokenOnAuthStateChanged(user.uid)
        try {
          // if (!__DEV__) AuthApi.recordUserLogin()
        } catch (e) {}
      } else {
        User.removeFromAsyncStorage()
        AuthApi.setApiServicesToken('')
        cache.clear()
        SentryPromise.then(({ Sentry }) =>
          Sentry.configureScope((scope) => scope.setUser(null))
        )
        Analytics.reset()
        updateAlgoliaUserTokenOnAuthStateChanged(null)
        globalSelectedVenueState.setGlobalState('selectedVenueId', '')

        if (Platform.OS === 'web' && process.env.NODE_ENV === 'production') {
          import('logrocket')
            .then((rocket) => rocket.default)
            .then((LogRocket) => {
              LogRocket.startNewSession()
            })

          // TODO re-add this with a hook
          // window.Intercom?.('shutdown')
          // if (showIntercom) {
          //   window.Intercom?.('boot', {
          //     app_id: INTERCOM_ID,
          //     hide_default_launcher: intercomIcon.hidden,
          //   })
          // }
        }
      }
    },
    [isAdminApp]
  )

  return (
    <DoormanProvider
      initialPhoneNumber={Platform.select({ web: '', default: undefined })}
      onAuthStateChanged={onAuthStateChanged}
      publicProjectId={AuthApi.doormanPublicProjectId}
    >
      {children}
    </DoormanProvider>
  )
}
