import React, { useRef, useState, useEffect, useCallback } from 'react'
import { View, useDripsyTheme } from 'dripsy'
import { Animated, FlatList, Platform, StyleSheet } from 'react-native'
import useLayout from '@beatgig/hooks/use-layout'
import useNativeDriver from '@beatgig/helpers/native-driver'
import ImageCarouselItem from './item'
import type { CarouselProps, Img } from './types'
import CarouselTitle from './title'
import CarouselArrow from './arrow'
import Press from '../press'
import Ionicons from '@expo/vector-icons/build/Ionicons'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import useHotKeys from './use-hotkeys'

const { divide, event } = Animated

const viewabilityConfig = {
  itemVisiblePercentThreshold: 51,
}

type ViewToken<I extends Img> = {
  image: I
  index: number
}

export default function ImageCarousel<I extends Img>(props: CarouselProps<I>) {
  const {
    sx = {},
    onSelectedImageChange,
    images,
    renderArrow,
    renderTitle,
    backgroundColor = 'background',
    initialImageIndex = 0,
    showsHeader = true,
    titleSx = {},
    renderImage,
    onRequestClose,
    imageSx,
    transparent,
    shouldCloseOnTapBackdrop = true,
  } = props

  const scrollX = useRef(new Animated.Value(0)).current
  const flatlist = useRef<FlatList>(null)

  const [viewableItems, setViewableItems] = useState<ViewToken<I>[]>([])

  const currentImage = viewableItems?.[0]

  const onViewableItemsChanged = useRef(({ viewableItems }) => {
    const itemsThatAreViewable: ViewToken<I>[] = viewableItems.filter(
      ({ isViewable }) => isViewable
    )
    // if (itemsThatAreViewable !== viewableArtists.current) {
    setViewableItems(itemsThatAreViewable)
    // }
  }).current

  const { colors } = useDripsyTheme().theme

  const onChange = useRef(onSelectedImageChange)
  useEffect(() => {
    onChange.current = onSelectedImageChange
  })

  useEffect(() => {
    if (currentImage) {
      onChange.current?.(currentImage)
    }
  }, [currentImage])

  const scrollToIndex = useCallback(({ index }: { index: number }) => {
    if (flatlist && flatlist.current) {
      flatlist.current.scrollToIndex({
        index,
        animated: true,
        // viewOffset: 0,
        // viewPosition: 1,
      })
    }
  }, [])
  const { width, onLayout, height } = useLayout()

  const hasInitialized = useRef(false)

  const { top, bottom } = useSafeAreaInsets()

  useEffect(() => {
    if (width && !hasInitialized.current) {
      hasInitialized.current = true
      if (typeof initialImageIndex === 'number' && initialImageIndex > -1) {
        flatlist.current?.scrollToIndex({
          index: initialImageIndex,
          animated: false,
        })
      }
    }
  }, [initialImageIndex, width])

  const canGoLeft = (currentImage?.index ?? 0) > 0
  const canGoRight = (currentImage?.index ?? 0) < (images?.length ?? 0) - 1

  const scrollRight = useCallback(() => {
    if (currentImage?.index == null || !canGoRight) return

    scrollToIndex({ index: currentImage.index + 1 })
  }, [canGoRight, currentImage, scrollToIndex])

  const scrollLeft = useCallback(() => {
    console.log('[scroll-left] hotkey', { canGoLeft, currentImage })
    if (currentImage?.index == null || !canGoLeft) return

    scrollToIndex({ index: currentImage.index - 1 })
  }, [canGoLeft, currentImage, scrollToIndex])

  useHotKeys(
    'right',
    scrollRight,
    {
      keydown: true,
      filter: () => true,
    },
    [scrollRight]
  )
  useHotKeys(
    'left',
    (e) => {
      e.preventDefault()
      scrollLeft()
    },
    [scrollLeft]
  )

  const index = divide(scrollX, width)
  // const window = useWindowDimensions()
  //   const { }

  const _renderTitle = () => {
    const titleProps: Parameters<
      Required<CarouselProps<I>>['renderTitle']
    >[0] = {
      count: images?.length ?? 0,
      currentImage: currentImage?.image,
      currentIndex: currentImage?.index,
      cursor: (currentImage?.index ?? 0) + 1,
      sx: { fontSize: 3, color: 'text', textAlign: 'center', ...titleSx },
    }
    if (renderTitle) renderTitle(titleProps)

    return <CarouselTitle {...titleProps} />
  }

  const _renderArrow = ({
    direction,
  }: Pick<
    Parameters<Required<CarouselProps<I>>['renderArrow']>[0],
    'direction'
  >) => {
    let isVisible = canGoLeft
    if (direction === 'right') {
      isVisible = canGoRight
    }
    const arrowProps: Parameters<
      Required<CarouselProps<I>>['renderArrow']
    >[0] = {
      direction,
      onPress: direction === 'right' ? scrollRight : scrollLeft,
      sx: {
        ...StyleSheet.absoluteFillObject,
        right: direction === 'left' ? 'auto' : 0,
        left: direction === 'right' ? 'auto' : 0,
        justifyContent: 'center',
        px: 2,
        opacity: isVisible ? 1 : 0,
        transitionProperty: 'opacity',
        transitionDuration: '0.25s',
      },
    }
    if (renderArrow) renderArrow(arrowProps)

    return <CarouselArrow {...arrowProps} />
  }

  const _renderHeader = () => {
    if (!showsHeader) return null

    return (
      <View
        sx={{
          flexDirection: 'row',
          py: 3,
          px: [2, 3],
          mt: top,
          alignItems: 'center',
        }}
      >
        <View sx={{ flexGrow: 0.5, flexBasis: '0%' }}>
          <Press
            hitSlop={{ top: 10, left: 10, right: 10, bottom: 10 }}
            onPress={onRequestClose}
          >
            {({ hovered, pressed }) => (
              <View
                sx={{
                  bg: hovered ? `text` : 'transparent',
                  borderColor: 'text',
                  borderRadius: 999,
                  borderWidth: 2,
                  // p: 3,
                  height: 40,
                  width: 40,
                  justifyContent: 'center',
                  alignItems: 'center',
                  transitionProperty: 'background-color',
                  transitionDuration: '0.3s',
                }}
              >
                <Ionicons
                  name={'close-outline'}
                  color={hovered ? colors?.background : colors?.text}
                  size={30}
                  style={Platform.select({
                    web: {
                      transitionProperty: 'color',
                      transitionDuration: '0.3s',
                    },
                    default: undefined,
                  })}
                />
              </View>
            )}
          </Press>
        </View>
        <View sx={{ flex: 1 }}>{_renderTitle()}</View>
        <View sx={{ flexGrow: 0.5, flexBasis: '0%' }}></View>
      </View>
    )
  }

  return (
    <View
      sx={{
        flex: 1,
        backgroundColor,
        ...Platform.select({
          web: {
            opacity: 0,
            animationKeyframes: {
              from: {
                opacity: 0,
              },
              to: {
                opacity: 1,
              },
            },
            animationDelay: '0.1s',
            animationDuration: '.3s',
            animationFillMode: 'forwards',
          },
          default: {},
        }),
        ...sx,
      }}
    >
      {_renderHeader()}
      <View
        sx={{
          flex: 1,
        }}
        onLayout={onLayout}
      >
        <Animated.FlatList
          data={images}
          ref={flatlist}
          keyExtractor={(item) => item.url}
          // initialScrollIndex={initialImageIndex || undefined} // for some reason 0 doesn't work...
          renderItem={({ item }) => (
            <ImageCarouselItem
              renderImage={renderImage}
              image={item}
              width={width}
              imageSx={imageSx}
              shouldCloseOnTapBackdrop={shouldCloseOnTapBackdrop}
              onRequestClose={onRequestClose}
            />
          )}
          // initialNumToRender={3}
          // removeClippedSubviews
          extraData={width}
          onScroll={event(
            [
              {
                nativeEvent: {
                  contentOffset: { x: scrollX },
                },
              },
            ],
            {
              useNativeDriver,
            }
          )}
          // @ts-ignore
          dataSet={{ 'paging-enabled-fix': true }}
          pagingEnabled
          horizontal
          onViewableItemsChanged={onViewableItemsChanged}
          getItemLayout={(_, index) => {
            return {
              length: width,
              offset: width * index,
              index,
            }
          }}
          showsHorizontalScrollIndicator={false}
          viewabilityConfig={viewabilityConfig}
        />
        {Platform.OS === 'web' && (
          <style
            dangerouslySetInnerHTML={{
              __html: `[data-paging-enabled-fix] > div > div > div {
height: 100%;
}`,
            }}
          />
        )}
        {_renderArrow({ direction: 'left' })}
        {_renderArrow({ direction: 'right' })}
      </View>
      <View sx={{ height: bottom }} />
      {/* <ImageCarouselThumbnails
        index={index}
        images={images}
        scrollToIndex={scrollToIndex}
        // selectedUrl={}
      /> */}
    </View>
  )
}
