import useAuth from './use-auth'

export default function useAmISignedIn() {
  const { loading, user } = useAuth()

  return {
    loading,
    amISignedIn: !!user,
  }
}
