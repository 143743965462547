import React from 'react'
import { View } from 'dripsy'
import Avatar from '@beatgig/design/components/avatar'
import { User } from '@beatgig/api/user'
import useSignOut from '@beatgig/auth-hooks/use-sign-out'
import useAmISignedIn from '@beatgig/auth-hooks/use-am-i-signed-in'
import useMyAccount from '@beatgig/auth-hooks/use-my-account'
import { Image } from '@beatgig/api-services'
import LinkToDashboard from '@beatgig/navigation/links/link-to-dashboard'
import useAnimatedHover from '@beatgig/helpers/use-animated-hover'
import { useRouting } from 'expo-next-react-navigation'
import useOpenAuthPopup from '@beatgig/auth-hooks/use-open-auth-popup'
import { AuthedRoutes } from '@beatgig/navigation/routes/authed-routes'
import { ImageSmall } from '@beatgig/api-services/image'

type Props = {
  shouldLink?: boolean
  shouldShowWhenUnauthenticated?: boolean
} & React.ComponentProps<typeof Avatar>

const empty = {}

export default function SmartUserAvatar({
  size = 'medium',
  shouldLink = true,
  // onPress,
  shouldShowWhenUnauthenticated = true,
  ...props
}: Props) {
  const signOut = useSignOut()
  const { loading, amISignedIn } = useAmISignedIn()
  const { data } = useMyAccount()
  const { isHovered, ref, resetStyle } = useAnimatedHover({
    webStyleKeys: ['transform', 'opacity'],
  })
  const openAuthPopup = useOpenAuthPopup()
  // const isHovered = false
  // const resetStyle = {}

  // if (loading) return null
  // if (!amISignedIn) return null

  let initials = ''
  let name = ''
  let profileImage: ImageSmall | null = null

  if (User.hasOnboarded(data)) {
    if (data?.profile_image) profileImage = data?.profile_image
    name = data?.name

    const firstInitial = data?.name?.[0]
    if (firstInitial) {
      initials = firstInitial
    }
    const secondInitial = data?.name?.split(' ')?.[1]?.[0]
    if (secondInitial) {
      initials += secondInitial
    }
  }

  const Wrapper = shouldLink && amISignedIn ? LinkToDashboard : React.Fragment

  const { navigate } = useRouting()

  const openDashboard = () => {
    navigate(AuthedRoutes.dashboard())
  }

  // if (!amISignedIn && !shouldShowWhenUnauthenticated) return null

  return (
    <View
      ref={ref}
      sx={{
        transform: [
          {
            scale: isHovered ? 1.1 : 1,
          },
        ],
        opacity: loading ? 0 : 1,
        width: loading ? 0 : undefined,
        overflow: 'hidden',
        display:
          !amISignedIn && !shouldShowWhenUnauthenticated ? 'none' : 'flex',
        ...resetStyle,
      }}
    >
      <Avatar
        {...props}
        text={name}
        // onPress={onPress ?? signOut}
        source={profileImage ? { uri: profileImage.url } : undefined}
        size={size}
        makeInitials
        // onPress={
        //   loading
        //     ? undefined
        //     : shouldLink && !amISignedIn
        //     ? openAuthPopup
        //     : openDashboard
        // }
      />
    </View>
  )
}
