import { Rename } from '@beatgig/helpers/types/rename'
import { APP_URL } from '@beatgig/constants'
import { ReferralsService } from '@beatgig/api-services/referrals'

export class ReferralApi {
  static get getPrivateReferralInfo() {
    return ReferralsService.getPrivateReferralInfo
  }
  static privateReferralInfoSWRKey({
    userId,
  }: Rename<
    Required<Parameters<typeof ReferralApi['getPrivateReferralInfo']>[0]>,
    'adminOnBehalfOf',
    'userId'
  >) {
    type Key = [string, 'private-referral-info']
    const key: Key = [userId, 'private-referral-info']

    return key
  }
  static makeUserReferralUrl({ userSlug }: { userSlug: string }) {
    return `https://${APP_URL}/join/${userSlug}`
  }
}
