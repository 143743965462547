import React from 'react'
import { View, Text } from 'dripsy'
import Link from '@beatgig/design/components/link'
import useMyAccount from '@beatgig/auth-hooks/use-my-account'
import LinkToDashboard from '@beatgig/navigation/links/link-to-dashboard'
import { User } from '@beatgig/api/user'
import { useRouting } from 'expo-next-react-navigation'
import { AuthedRoutes } from '@beatgig/navigation/routes/authed-routes'
import SmartLinkToCalendar from '../smart-link-to-calendar'
import { BAR_PRODUCT_FEATURE_FLAG_HIDDEN } from '@beatgig/constants/feature-flags'

type Props = Omit<React.ComponentProps<typeof Link>, 'onPress' | 'children'>

export default function SmartLinkToBookings(props: Props) {
  const { data } = useMyAccount()
  const { navigate } = useRouting()

  if (!User.hasOnboarded(data)) return null

  // here we show SmartLinkToCalendar instead
  if (User.isMultiVenueBuyer(data) && !BAR_PRODUCT_FEATURE_FLAG_HIDDEN)
    return <SmartLinkToCalendar {...props} />

  const onPress = () => {
    navigate(AuthedRoutes.bookings())
  }

  return (
    <Link block {...props} onPress={onPress}>
      Bookings
    </Link>
  )
}
