import React from 'react'
import {
  initialWindowMetrics,
  SafeAreaProvider,
} from 'react-native-safe-area-context'
import Dripsy from './Dripsy'
import { NavigationProvider } from './Navigation'
import { ActionSheetProvider } from '@expo/react-native-action-sheet'
import Doorman from './Doorman'
import SentryProvider from './Sentry'
import NextRouterProvider from './Router'
import { Platform } from 'react-native'

import { NotifierWrapper } from 'react-native-notifier'
import Popups from './popups'
import QueryProvider from '@beatgig/queries/core/provider'

type Props = {
  children: React.ReactNode
}

const initialMetrics: React.ComponentProps<
  typeof SafeAreaProvider
>['initialMetrics'] = {
  frame: { x: 0, y: 0, width: 0, height: 0 },
  insets: { top: 0, left: 0, right: 0, bottom: 0 },
}

export default function Providers(props: Props) {
  const { children } = props

  //
  return (
    <Doorman>
      <QueryProvider>
        <SentryProvider>
          <Dripsy>
            <NavigationProvider>
              <ActionSheetProvider>
                <SafeAreaProvider initialMetrics={initialWindowMetrics}>
                  <NextRouterProvider>
                    <NotifierWrapper>{children}</NotifierWrapper>
                    {/* TODO enable this for mobile? */}
                    {Platform.OS === 'web' && <Popups />}
                  </NextRouterProvider>
                </SafeAreaProvider>
              </ActionSheetProvider>
            </NavigationProvider>
          </Dripsy>
        </SentryProvider>
      </QueryProvider>
    </Doorman>
  )
}
