import React from 'react'
import Button from '@beatgig/components/button'
import useAuth from '@beatgig/auth-hooks/use-auth'
import useOpenAuthPopup from '@beatgig/auth-hooks/use-open-auth-popup'
import { Platform } from 'react-native'

type Props = Omit<
  React.ComponentProps<typeof Button>,
  'children' | 'onPress'
> & {
  children?: React.ReactNode
}

export default function SmartSignUpButton({
  sx = {},
  children = 'Sign Up',
  ...props
}: Props) {
  const { loading, user } = useAuth()

  const open = useOpenAuthPopup()

  if (user) return null
  return (
    <Button
      {...props}
      sx={{
        ...Platform.select({
          web: {
            animationKeyframes: {
              from: { opacity: 0, transform: [{ translateY: 5 }] },
              to: { opacity: 1, transform: [{ translateY: 0 }] },
            } as any,
            animationDuration: '0.5s',
          },
          default: {},
        }),
        // fontSize: 2,
        ...sx,
      }}
      onPress={() => open()}
    >
      {children}
    </Button>
  )
}
