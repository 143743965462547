import * as React from 'react'
import { View } from 'dripsy'

import { BlurProps } from './BlurView.types'
import getBackgroundColor from './getBackgroundColor'

const BlurView = React.memo(function BlurView({
  tint = 'default',
  intensity = 50,
  // style,
  ...props
}: BlurProps) {
  // don't collide this style with other blurs
  const id = intensity + '-' + tint
  const blurStyle = getBlurStyle(id, { tint, intensity })
  return (
    <>
      <View
        {...props}
        // @ts-ignore
        dataSet={{ blur: id }}
        // style={style}
      />
      <style dangerouslySetInnerHTML={{ __html: blurStyle }} />
    </>
  )
})

function getBlurStyle(id: string, { intensity, tint }) {
  // https://developer.mozilla.org/en-US/docs/Web/CSS/@supports
  const style = `[data-blur="${id}"] {
  background-color: ${getBackgroundColor(intensity, tint)}
}
@supports (backdrop-filter: blur(1px)) {
  [data-blur="${id}"] {
    backdrop-filter: saturate(180%) blur(${intensity * 0.2}px)
  }
}
@supports (-webkit-backdrop-filter: blur(1px)) {
  [data-blur="${id}"] {
      -webkit-backdrop-filter: saturate(180%) blur(${intensity * 0.2}px)
  }
}`
  return style
}

export default BlurView
