import React, { useMemo } from 'react'
import { View, Text } from 'dripsy'
import { useRouting } from 'expo-next-react-navigation'
import { StyleSheet, Platform } from 'react-native'
import { m as motion } from 'framer-motion'
import BlurView from '@beatgig/components/blur/index'
import { webBottomTabNavigationVisible } from './types'
import Sizer from '@beatgig/components/sizer'
import useAuthenticatedPages from '@beatgig/auth-hooks/use-authenticated-pages'
import TabItem from './tab-item'
import Grid from '@beatgig/design/components/grid'

export default function WebBottomTabNavigation() {
  // const isIntercomHidden = useResponsiveValue(webBottomTabNavigationVisible)

  const { pages } = useAuthenticatedPages()

  const { pathname } = useRouting()

  const items = useMemo(() => {
    return [...pages].filter(({ hideFromTabBar }) => !hideFromTabBar)
  }, [pages])

  const selected = useMemo(
    () =>
      pages?.find(({ value, isActive }) => isActive?.(value, pathname))
        ?.value ?? null,
    [pages, pathname]
  )

  const renderItems = useMemo(() => {
    return items.map(({ title, onPress, value, iconName }) => {
      return (
        <TabItem
          label={title}
          key={title}
          onPress={onPress}
          isActive={selected === value}
          iconName={iconName}
        />
      )
    })
  }, [items, selected])

  if (!items.length) return null

  return (
    <Sizer
      display={webBottomTabNavigationVisible.map((shown) =>
        shown ? 'flex' : 'none'
      )}
      {...{
        position: 'fixed',
        bottom: 0,
        right: 0,
        left: 0,
      }}
    >
      <View
        sx={{
          px: 2,
          bg: 'muted',
        }}
      >
        <BlurView
          style={StyleSheet.absoluteFillObject}
          tint="dark"
          intensity={Platform.select({ web: 80, default: 100 })}
        />
        <Grid gap={2} columns={items.length}>
          {renderItems}
        </Grid>
        {Platform.OS === 'web' && (
          <View style={{ paddingBottom: 'env(safe-area-inset-bottom)' }} />
        )}
      </View>
    </Sizer>
  )
}
