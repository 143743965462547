import React from 'react'

type Props = {
  children: React.ReactNode
}
import { NavigationContainer } from '@react-navigation/native'
import navigationRef from '@beatgig/navigation/service/ref'
// import * as Linking from 'expo-linking'
// import { useAdminContext } from './Admin/context'

// Linking.createURL is available as of expo@40.0.1 and expo-linking@2.0.1. If
// you are using older versions, you can upgrade or use Linking.makeUrl instead,
// but note that your deep links in standalone apps will be in the format
// scheme:/// rather than scheme:// if you use makeUrl.
// const prefix = Linking.makeUrl('/')

const documentTitle = {
  enabled: false,
}

export function NavigationProvider({ children }: Props) {
  // const maybeAdminContext = useAdminContext()

  return (
    <NavigationContainer
      documentTitle={documentTitle}
      // linking={
      //   maybeAdminContext?.isAdminApp
      //     ? {
      //         prefixes: [
      //           prefix,
      //           'https://*.beatgig.com',
      //           'https://*.vercel.app',
      //         ],
      //         config: {
      //           screens: {
      //             Calendar: 'calendar',
      //           },
      //         },
      //       }
      //     : undefined
      // }
      // initialState={
      //   maybeAdminContext?.isAdminApp
      //     ? {
      //         routes: [
      //           {
      //             name: 'Bookings',
      //             state: {
      //               index: 0,
      //               routes: [{ name: 'Bookings' }],
      //             },
      //           },
      //         ],
      //       }
      //     : undefined
      // }
      ref={navigationRef}
    >
      {children}
    </NavigationContainer>
  )
}
