import { Platform } from 'react-native'
import defaultBreakpoints from './breakpoints'

const VercelColorPallet = {
  vercelVioletLighter: '#E3D7FC',
  vercelVioletLight: '#8A63D2',
  vercelViolet: '#7928CA',
  vercelVioletDark: '#4C2889',
  vercelErrorLighter: '#F7D4D6',
  vercelErrorLight: '#F33F33',
  vercelError: '#FF0000',
  vercelErrorDark: '#E60000',
  // vercelAccent1: '#111111',
  vercelAccent2: '#333333',
  vercelAccent3: '#444444',
  vercelAccent4: '#666666',
  vercelAccent5: '#888888',
  vercelAccent6: '#999999',
  vercelAccent7: '#EAEAEA',
  vercelAccent8: '#FAFAFA',
  vercelSuccessLighter: '#D3E5FF',
  vercelSuccessLight: '#3291FF',
  vercelSuccess: '#0070F3',
  vercelSuccessDark: '#0761D1',
  vercelWarningLighter: '#FFEFCF',
  vercelWarningLight: '#F7B955',
  vercelWarning: '#F5A623',
  vercelWarningDark: '#AB570A',
  vercelCyanLighter: '#AAFFEC',
  vercelCyanLight: '#79FFE1',
  vercelCyan: '#50E3C2',
  vercelCyanDark: '#29BC9B',
  vercelHighlightPurple: '#F81CE5',
  vercelHighlightMagenta: '#EB367F',
  vercelHighlightPink: '#FF0080',
  vercelHighlightYellow: '#FFF500',
} as const

const ColorPallet = {
  black: '#000000',
  white: '#ffffff',
  darkGray: '#131313',
  lightGray: '#b3b3b3',
  yellow: '#d3d71b',
  yellowOpen: '#ffd43b',
  yellowBold: '#FEF600',
  paradisePink: '#E83F6F',
  darkJungle: '#0D1B1E',
  netflixBg: '#141414',
  spotifySurfaceDark: '#040404',
  spotifySurfaceLight: '#282828',
  hotPink: '#EC598F',
  ...VercelColorPallet,
  vercelBackground: '#000000',
  vercelBorder: '#333333',
  vercelMuted: '#111111',
  vercelYellow: '#FFF500',
  vercelMutedText: '#888888',
  netflixSecondaryButtonBg: 'rgba(109,109,110,0.5)',
  linearBg: '#1F2023',
  linearBg2: '#27282B',
  linearMutedText: 'rgb(138, 143, 152)',
  linearBorder: 'rgb(48, 50, 54)',
} as const

const StripeColorPallet = {
  accent: '#96f',
  paymentLinks: {
    // https://stripe.com/payments/payment-links
    blendBackground: '#11efe3',
    blendForeground: '#96f',
    shadeOneColor: '#16cbe1',
    shadoeTwoColor: '#20b5e3',
    shadeThreeColor: '#1086db',
    shadeFourColor: '#1959e6',
    shadeFiveColor: '#96f',
    shadeSixColor: '#002c59',
    shadeSevenColor: '#00c4c4',
  },
}

// nice gradients: https://uigradients.com/#Kyoto
// vercel colors: https://vercel.com/design/color
// color inspiration: https://dribbble.com/shots/13961133-Alabama-app-Iteration

const fontSizes = [
  10,
  12,
  14,
  16,
  18,
  20,
  24,
  28,
  32,
  38,
  48,
  56,
  64,
  72,
  96,
].map((fontSize) => fontSize + 2)
const radii: number[] & { rounded?: number } = [0, 2, 4, 8, 16, 32]
radii.rounded = 999999

const space = {
  '0': 0,
  '1': 4,
  '2': 8,
  '3': 16,
  '4': 32,
  '5': 64,
  '6': 128,
  '7': 256,
  '8': 512,
} as const

const baseButton = {
  borderRadius: 'rounded',
  borderWidth: 2,
  px: 3,
  py: (theme) => theme.space[1] * 1.5 + 0.0001,
  borderStyle: 'solid',
  label: {
    fontWeight: '600',
    fontSize: 2,
    textAlign: 'center',
  },
}

const netflixColors = {
  secondaryButtonBg: ColorPallet.netflixSecondaryButtonBg,
  background: ColorPallet.netflixBg,
}

const vercelColors = {
  primary: ColorPallet.vercelYellow,
  secondary: ColorPallet.vercelViolet,
  accent: ColorPallet.vercelHighlightPink,
  danger: ColorPallet.vercelError,
  success: ColorPallet.vercelSuccess,
  warning: ColorPallet.vercelWarning,
  callout: ColorPallet.vercelCyan,
  calloutLight: ColorPallet.vercelCyanLight,
  calloutLighter: ColorPallet.vercelCyanLighter,
  error: ColorPallet.vercelError,
  errorLight: ColorPallet.vercelErrorLight,
  errorLighter: ColorPallet.vercelErrorLighter,
  background: ColorPallet.vercelBackground,
  text: ColorPallet.white,
  border: ColorPallet.vercelBorder,
  mutedText: ColorPallet.vercelMutedText,
  muted: ColorPallet.vercelMuted,
  muted2: ColorPallet.vercelAccent2,
  muted3: ColorPallet.vercelAccent3,
  muted4: ColorPallet.vercelAccent4,
  muted5: ColorPallet.vercelAccent5,
  muted6: ColorPallet.vercelAccent6,
  muted7: ColorPallet.vercelAccent7,
  muted8: ColorPallet.vercelAccent8,
  // ...Object.entries(VercelColorPallet)
  //   .filter(([key, color]) => key.toLowerCase().includes('accent'))
  //   .reduce(
  //     (prev, [key, color]) =>
  //       ({
  //         ...prev,
  //         // replace accent key with "muted"
  //         [`muted${key.toLowerCase().split('accent')[1]}` as const]: color,
  //       } as const),
  //     {}
  //   ),
} as const

const linearColors = {
  // border: ColorPallet.linearBorder,

  // mutedText: ColorPallet.linearMutedText,

  // background: ColorPallet.linearBg,
  // muted: ColorPallet.linearBg2,
  muted: ColorPallet.linearBg,
} as const

const beatgigColors = {
  // background: ColorPallet.netflixBg,
  // text: ColorPallet.white,
  // muted: ColorPallet.spotifySurfaceLight,
  // mutedText: ColorPallet.lightGray,
  // primary: ColorPallet.yellowBold,
  // secondary: ColorPallet.paradisePink,
  // surface: ColorPallet.spotifySurfaceDark,
  // danger: ColorPallet.hotPink,
  // border: ColorPallet.vercelBorder,
}

const robinhoodColors = {
  success: '#00C806',
} as const

// https://spotify.design/
const spotifyDesignColors = {
  primary: '#FFE818',
}

const fontFamily = 'circ'

const webFont = (font: string) =>
  Platform.select({
    web: `${font}, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif`,
    default: font,
  })

const theme = {
  customFonts: {
    circ: {
      bold: webFont('CircularStd-Bold'),
      default: webFont('circ'),
      normal: webFont('circ'),
      '400': webFont('circ'),
      '500': webFont('CircularStd-Medium'),
      '600': webFont('CircularStd-Bold'),
      '700': webFont('CircularStd-Bold'),
      '800': webFont('CircularStd-Black'),
      '900': webFont('CircularStd-Black'),
    },
  },
  fonts: {
    body: fontFamily,
    root: fontFamily,
  },
  fontFamilies: {
    body: fontFamily,
  },
  colors: {
    // ...beatgigColors,
    ...vercelColors,
    ...robinhoodColors,
    ...linearColors,
    backgroundLight: ColorPallet.netflixBg,
    blue: VercelColorPallet.vercelSuccess,
    cyan: VercelColorPallet.vercelCyan,
    modes: {
      vercel: {
        background: ColorPallet.vercelBackground,
        text: ColorPallet.white,
        muted: ColorPallet.vercelMuted,
        mutedText: ColorPallet.vercelMutedText,
        primary: ColorPallet.vercelYellow,
        secondary: ColorPallet.vercelViolet,
        surface: ColorPallet.spotifySurfaceDark,
        danger: ColorPallet.vercelError,
        border: ColorPallet.vercelBorder,
      },
    },
  },
  // fontSizes: [12, 14, 16, 18, 20, 24, 28, 32, 48, 64],
  fontSizes,
  // space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  space,
  radii,
  fontWeights: {
    // @ts-ignore
    normal: '500',
    // @ts-ignore

    semibold: '600',
    // @ts-ignore

    bold: '700',
    // @ts-ignore

    black: '900',
  },
  text: {
    caps: {
      fontWeight: 'bold',
      textTransform: 'uppercase',
      letterSpacing: 0.1,
      fontFamily: 'body',
      color: 'text',
      fontSize: 2,
    },
    body: {
      fontFamily: 'body',
      color: 'text',
      fontSize: 2,
    },
    inherit: {},
    h1: {
      color: 'text',
      fontSize: 9,
      mt: 0,
      mb: 3,
      fontFamily: 'body',
      fontWeight: 'bold',
    },
    h2: {
      color: 'text',
      fontSize: 8,
      mt: 0,
      mb: 3,
      fontFamily: 'body',
      fontWeight: 'bold',
    },
    h3: {
      color: 'text',
      fontSize: 7,
      mt: 0,
      mb: 3,
      fontFamily: 'body',
      fontWeight: 'bold',
    },
    h4: {
      color: 'text',
      fontSize: [6, 7],
      mt: 0,
      mb: 3,
      fontFamily: 'body',
      fontWeight: 'bold',
    },
    h5: {
      color: 'text',
      fontSize: 6,
      mt: 0,
      mb: 3,
      fontFamily: 'body',
      fontWeight: 'bold',
    },
    descriptionTitle: {
      fontSize: 1,
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
      color: 'muted6',
      fontWeight: '600',
      fontFamily: 'body',
    },
    onImage: {
      textShadowOffset: { width: 1, height: 1 },
      textShadowRadius: 5,
      textShadowColor: '#00000099',
    },
  },
  forms: {
    input: {
      fontFamily: 'body',
    },
  },
  buttons: {
    primary: {
      ...baseButton,
      bg: 'primary',
      borderColor: 'primary',
      label: {
        color: 'background',
        ...baseButton.label,
      },
    },
    outlined: {
      ...baseButton,
      bg: 'transparent',
      borderColor: 'primary',
      label: {
        color: 'text',
        ...baseButton.label,
      },
    },
    small: {
      ...baseButton,
      px: 3,
      py: 1,
      bg: 'primary',
      borderColor: 'primary',
      label: {
        color: 'background',
        ...baseButton.label,
        fontSize: 1,
      },
    },
    smallOutlined: {
      ...baseButton,
      // px: 4,
      // py: 2,
      bg: 'transparent',
      borderColor: 'primary',
      borderWidth: 1,
      label: {
        color: 'text',
        ...baseButton.label,
        fontSize: 1,
      },
    },
    thin: {
      ...baseButton,
      borderWidth: 1,
      label: {
        fontWeight: '500',
      },
    },
    secondary: {
      ...baseButton,
      bg: `${netflixColors.secondaryButtonBg}`,
      borderColor: `${netflixColors.secondaryButtonBg}`,
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,

      elevation: 5,
      label: {
        ...baseButton.label,
        color: 'text',
      },
    },
  },
  borderWidths: [0, 1, 2, 4, 8, 16],
  layout: {
    container: {
      px: 2,
      maxWidth: 908,
      alignSelf: 'center',
      width: '100%',
    },
    venueScreenContainer: {
      px: 2,
      maxWidth: 1150,
      alignSelf: 'center',
      width: '100%',
    },
    wide: {
      px: 2,
      maxWidth: 1200,
      alignSelf: 'center',
      width: '100%',
    },
    padded: {
      px: 3,
    },
  },
  lineHeights: fontSizes.map((size) => size * 1.3),
  shadows: {},
} as const

export default theme
