/**
 * Compose a `console.log` that prints what you give it.
 *
 * Example:
 *
 * ```js
 * useUsers({
 *   onSuccess: easyLog('users')
 * })
 *
 * // logs: '[users]', [user1, user2...]
 * ```
 *
 * To put it in a {[key]: data} form, do this:
 *
 * ```js
 * useUsers({
 *   onSuccess: easyLog('users', 'data')
 * })
 *
 * // logs: '[users]', { data: [user1, user2...] }
 * ```
 */
export default function easyLog(title: string, descriptor?: string) {
  return (innerArgs: any) => {
    console.log(
      `[${title}]`,
      descriptor ? { [descriptor]: innerArgs } : innerArgs
    )
  }
}
