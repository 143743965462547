import React from 'react'
// import WebAuthPopup from '@beatgig/auth-components/auth-popup'
// import ArtistLikesPopup from '@beatgig/ui/artist-likes-popup'
import { Platform } from 'react-native'
import ImageCarouselPopup from '@beatgig/screens/image-carousel-popup'
import useArtistLikesPopupState from '@beatgig/ui/artist-likes-popup/use-artist-likes-state'
import useAuthPopupState from '@beatgig/auth-hooks/use-auth-popup-state'

import dynamic from 'next/dynamic'

const WebAuthPopup = dynamic(
  () => import('@beatgig/auth-components/auth-popup'),
  {
    ssr: false,
  }
)
const ArtistLikesPopup = dynamic(
  () => import('@beatgig/ui/artist-likes-popup'),
  {
    ssr: false,
  }
)

export default function Popups() {
  const [likesOpen] = useArtistLikesPopupState()
  const [authOpen] = useAuthPopupState()

  return (
    <>
      {Platform.OS === 'web' && authOpen && <WebAuthPopup />}
      {Platform.OS === 'web' && likesOpen && <ArtistLikesPopup />}
      <ImageCarouselPopup />
    </>
  )
}
