import React, { useEffect, useState, useRef } from 'react'
import RouterContext from './context'
import Router from 'next/router'

type Props = {
  children: React.ReactNode
}

export default function NextRouterProvider(props: Props) {
  const { children } = props

  const [state, setState] = useState({
    canGoBack: () => false as boolean,
  })

  // const { events } = useRouter()

  const hasClientSideRouted = useRef(false)

  useEffect(() => {
    const { events } = Router
    if (!events) return

    const handleStart = () => {
      hasClientSideRouted.current = true
    }
    events.on('routeChangeStart', handleStart)
    const handleChange = () => {
      if (hasClientSideRouted.current) {
        // window.Intercom('update')

        setState((state) => {
          if (!state.canGoBack()) {
            return {
              ...state,
              canGoBack: () => true,
            }
          }
          return state
        })
      }
    }
    events.on('routeChangeComplete', handleChange)

    return () => {
      events.off('routeChangeComplete', handleChange)
      events.off('routeChangeStart', handleStart)
    }
  }, [])

  return (
    <RouterContext.Provider value={state}>{children}</RouterContext.Provider>
  )
}
