import Link from '@beatgig/design/components/link'
import React from 'react'
import * as DropdownMenu from '../dropdown-menu'
import { View, Text, useSx } from 'dripsy'
import PublicRoutes from '@beatgig/navigation/routes/public-routes'
import { DripsyTheme } from '@beatgig/theme'
import NextLink from 'next/link'
import Ionicons, { IconsBaseProps } from '@beatgig/design/components/ionicons'
import { motion } from 'framer-motion'
import useTheme from '@beatgig/theme/use-theme'
import { useProductsDropdown } from './use-products-dropdown'

type LinkSchema = {
  title: string
  description: string
  to: string
  icon: IconsBaseProps['name']
  color: keyof DripsyTheme['colors']
}

const links: LinkSchema[] = [
  {
    title: 'Colleges',
    description: 'For greek organizations & university program boards',
    to: PublicRoutes.marketingPage('colleges').web.path,
    color: 'primary',
    icon: 'school-outline',
  },
  {
    title: 'Venues',
    description: 'For bars, nightclubs, restaurants, breweries, & vineyards',
    to: PublicRoutes.marketingPage('venues').web.path,
    color: 'callout',
    icon: 'business-outline',
  },
  {
    title: 'Artists',
    description: 'For artists, managers, & agents',
    to: PublicRoutes.marketingPage('artists').web.path,
    color: 'accent',
    icon: 'mic-outline',
  },
]

enum MOTION_VARIANTS {
  INITIAL = 'initial',
  HOVERED = 'hovered',
}

function LinkItem({
  title,
  description,
  color,
  icon,
  to,
}: LinkSchema & { isFirst: boolean }) {
  const sx = useSx()
  const { colors } = useTheme()
  return (
    <View sx={{ mt: 3, borderRadius: 3, overflow: 'hidden' }}>
      <motion.div
        initial={MOTION_VARIANTS.INITIAL}
        whileHover={MOTION_VARIANTS.HOVERED}
        variants={{
          [MOTION_VARIANTS.INITIAL]: {
            backgroundColor: colors.background,
          },
          [MOTION_VARIANTS.HOVERED]: {
            backgroundColor: colors.muted,
          },
        }}
        // transition={{
        //   duration: 0.2,
        // }}
      >
        <NextLink href={to} passHref>
          <View accessibilityRole="link" sx={{ p: 3 }}>
            <View sx={{ flexDirection: 'row', alignItems: 'center' }}>
              <View
                sx={{
                  size: 50,
                  borderRadius: 'rounded',
                  mr: 4,
                  bg: color,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Ionicons name={icon} size={30} color="background" />
              </View>
              <View sx={{ flex: 1 }}>
                <View sx={{ flexDirection: 'row', alignItems: 'center' }}>
                  <Text sx={{ fontWeight: '500', fontSize: 4, mr: 2 }}>
                    {title}
                  </Text>
                  <motion.div
                    variants={{
                      [MOTION_VARIANTS.INITIAL]: {
                        opacity: 0,
                        translateX: -5,
                      },
                      [MOTION_VARIANTS.HOVERED]: {
                        opacity: 1,
                        translateX: 0,
                      },
                    }}
                  >
                    <Ionicons name="arrow-forward-outline" size={16} />
                  </motion.div>
                </View>
                <Text sx={{ color: 'mutedText' }}>{description}</Text>
              </View>
            </View>
          </View>
        </NextLink>
      </motion.div>
    </View>
  )
}

export function ProductsDropdown({
  controller,
}: Pick<React.ComponentProps<typeof DropdownMenu.Popper>, 'controller'>) {
  const { isDropdownInHeader } = useProductsDropdown()
  if (!isDropdownInHeader) return null

  return (
    <View sx={{ mr: 2 }}>
      <DropdownMenu.Popper
        controller={controller}
        trigger={
          <DropdownMenu.Trigger>
            <Link icon iconName="chevron-down" block disabled={false}>
              Products
            </Link>
          </DropdownMenu.Trigger>
        }
      >
        <DropdownMenu.Dropdown>
          <View
            sx={{
              width: 500,
              bg: 'background',
              borderRadius: 4,
              boxShadow: `rgb(255 255 255 / 10%) 0px 50px 100px -20px, rgb(255 255 255 / 50%) 0px 30px 60px -30px`,
              // maxWidth: '100%',
              // borderWidth: 1,
              // borderColor: 'muted6',
            }}
          >
            <View sx={{ p: 3 }}>
              <Text sx={{ color: 'mutedText', px: 3, mt: 3 }} variant="caps">
                Products
              </Text>
              {links.map((link, i) => (
                <LinkItem isFirst={i === 0} key={link.title} {...link} />
              ))}
            </View>
          </View>
        </DropdownMenu.Dropdown>
      </DropdownMenu.Popper>
    </View>
  )
}
