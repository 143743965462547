import { useCallback } from 'react'
import { User } from '@beatgig/api/user'
import useMyAccount from '@beatgig/auth-hooks/use-my-account'
import useArtistLikesPopupState from './use-artist-likes-state'

export default function useOpenArtistLikesPopup() {
  // const [, setIsOpen] = useArtistLikesPopupState()
  const { data: account } = useMyAccount()
  const onboarded = User.hasOnboarded(account)

  return useCallback(
    ({ close }: { close?: boolean } = {}) => {
      // if (User.hasOnboarded(account)) {
      //   User.refetchMyLikedArtistsSWR(account?.id)
      // }
      if (onboarded || close) {
        useArtistLikesPopupState.set('isOpen', !close)
      }
    },
    [onboarded]
  )
}
