import { ImageSmall } from '@beatgig/api-services/image'
import { CarouselProps, Img } from '@beatgig/components/image-carousel/types'
import { createGlobalState } from 'react-hooks-global-state'
import { useCallback } from 'react'

type Closed = {
  isOpen: false
  props: Record<string, never>
}

type Open<I extends Img = ImageSmall> = {
  isOpen: true
  props: CarouselProps<I>
}

const onRequestClose = () => void null

type State = {
  carousel: { isOpen: boolean; props: CarouselProps<ImageSmall> }
}

const initialState: State = {
  carousel: {
    isOpen: false,
    props: {
      images: [],
      onRequestClose,
    },
  },
}
export const globalImageCarouselState = createGlobalState(initialState)

export const openGlobalImageCarousel = <I extends ImageSmall = ImageSmall>(
  props: Open<I>['props']
) => {
  globalImageCarouselState.setGlobalState('carousel', {
    isOpen: true,
    props,
  })
}

export const closeGlobalImageCarousel = () => {
  globalImageCarouselState.setGlobalState('carousel', {
    isOpen: false,
    props: {
      images: [],
      onRequestClose,
    },
  })
}

export default function useImageCarouselPopup<I extends Img = ImageSmall>() {
  const [state] = globalImageCarouselState.useGlobalState('carousel')

  // const open = useCallback(
  //   (props: Open<I>['props']) => {
  //     setState({
  //       isOpen: true,
  //       props,
  //     })
  //   },
  //   [setState]
  // )

  // const close = useCallback(() => {
  //   setState({
  //     isOpen: false,
  //     props: {
  //       images: [],
  //       onRequestClose,
  //     },
  //   })
  // }, [setState])

  return [
    state,
    { open: openGlobalImageCarousel, close: closeGlobalImageCarousel },
  ] as const
}
