import React, { useState, useRef, SetStateAction, Dispatch } from 'react'
import Pressable from '../../press'
import { usePopper } from 'react-popper'
import { View } from 'dripsy'
import type { View as ViewType } from 'react-native'
import { Dimensions } from 'react-native'
import useClickAway from '@beatgig/hooks/use-click-away'
import webHeaderAccountMenu from '@beatgig/ui/web-header-account-menu/use-web-header-account-menu-open'
import { Router } from 'next/router'

const Context = React.createContext({
  dropdownShown: false,
  setDropdownShown: ((shown: boolean) => {
    console.error('[set-dropdown-shown] missing provider')
  }) as React.Dispatch<React.SetStateAction<boolean>>,
  triggerRef: (null as any) as React.RefObject<ViewType>,
})

const useDropdown = () => React.useContext(Context)

type PopperProps = Parameters<typeof usePopper>

type MenuController = { dismiss: () => void }

type Props = {
  children: React.ReactNode
  trigger: React.ReactNode
  controller?: React.Ref<MenuController>
}

const useMenuController = () => {
  const ref = useRef<MenuController>(null)

  return ref
}

const Popper = ({ children, trigger, controller }: Props) => {
  const [referenceElement, setReferenceElement] = useState<PopperProps[0]>(null)
  const [popperElement, setPopperElement] = useState<PopperProps[1]>(null)
  const [arrowElement, setArrowElement] = useState<HTMLElement>()

  const triggerRef = useRef<ViewType>(null)

  const [dropdownShown, setDropdownShown] = React.useState(false)

  React.useImperativeHandle(controller, () => ({
    dismiss: () => setDropdownShown(false),
  }))

  React.useEffect(() => {
    // unrelated side-effect: close the account dropdown. hacky, but whatever lol
    if (dropdownShown && webHeaderAccountMenu.getGlobalState('isOpen')) {
      webHeaderAccountMenu.setGlobalState('isOpen', false)
    }
  }, [dropdownShown])

  React.useEffect(() => {
    const callback = () => {
      setDropdownShown(false)
    }
    Router.events.on('routeChangeStart', callback)

    return () => {
      Router.events.off('routeChangeStart', callback)
    }
  }, [])

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: 'arrow', options: { element: arrowElement } }],
  })

  return (
    <Context.Provider value={{ dropdownShown, setDropdownShown, triggerRef }}>
      <View
        // @ts-expect-error RNW types missing for web
        onMouseLeave={() => setDropdownShown(false)}
      >
        {/* <div ref={(ref) => setReferenceElement(ref)}></div> */}
        {trigger}
        {/* <div ref={setPopperElement} style={styles.popper} {...attributes.popper}> */}
        {/* <View sx={{  }}> */}
        {children}
      </View>
      {/* </View> */}
      {/* <div
            ref={(ref) => ref && setArrowElement(ref)}
            style={styles.arrow}
          /> */}
      {/* </div> */}
    </Context.Provider>
  )
}

const Trigger = ({ children }: { children: React.ReactNode }) => {
  const { setDropdownShown, triggerRef } = useDropdown()

  return (
    <Pressable
      onHoverIn={() => {
        setDropdownShown(true)
      }}
      ref={triggerRef as any}
      sx={{
        cursor: 'pointer',
      }}
    >
      {children}
    </Pressable>
  )
}

const Dropdown = ({ children }: { children: React.ReactNode }) => {
  const { setDropdownShown, dropdownShown, triggerRef } = useDropdown()
  // const [contentWidth, setContentWidth] = React.useState(0)

  const [left, setLeft] = useState(0)

  // const [, rerender] = React.useState([])

  const container = useRef<ViewType>(null)

  const ref = useRef(null)

  const onMeasureContent: React.ComponentProps<
    typeof ViewType
  >['onLayout'] = async (e) => {
    const { layout } = e.nativeEvent
    const {
      width: dropdownWidth,
      // @ts-expect-error RNW number type
      left: dropdownOffsetX,
    } = layout
    const screenWidth = Dimensions.get('window').width

    console.log('[dropdown-menu] onMeasureContent')

    try {
      const triggerMeasurement =
        (await new Promise<{ width: number }>((resolve) =>
          triggerRef.current?.measureInWindow((x, y, width) => {
            resolve({ width })
          })
        )) || 0

      const moveLeftInOrderToCenterBy =
        (dropdownWidth - triggerMeasurement.width) / 2
      let left = -moveLeftInOrderToCenterBy

      // const rightBoundary =
      //   screenWidth -
      //   triggerMeasurement.width -
      //   dropdownWidth +
      //   moveLeftInOrderToCenterBy

      const offsetRight =
        screenWidth -
        dropdownWidth -
        dropdownOffsetX +
        moveLeftInOrderToCenterBy

      const minimumRightBoundary = 30

      if (offsetRight < minimumRightBoundary) {
        // if we go too far right, then we need to shift it left
        left -= Math.abs(offsetRight) + minimumRightBoundary

        // const overflowRight = minimumRightBoundary - rightBoundary
        // translateX -= overflowRight
        // moveLeftBy = screenWidth - dropdownWidth - minimumRightBoundary
      }

      // rerender([])

      setLeft(left)
      // container.current?.setNativeProps({
      //   style: {
      //     transform: [
      //       {
      //         translateX,
      //       },
      //     ],
      //   },
      // })
    } catch (e) {
      console.error('[menu-dropdown-error]', e)
    }
  }

  return (
    <Pressable
      onHoverOut={() => setDropdownShown(false)}
      ref={ref}
      pointerEvents={dropdownShown ? 'auto' : 'none'}
      sx={{
        cursor: 'auto',
        perspective: '2000px',
      }}
    >
      <View
        ref={container as any}
        sx={{
          // transform: [
          //   {
          //     translateX: '-50%',
          //   },
          //   // {
          //   //   translateX: dropdownShown ? 461 : 0,
          //   // },
          // ],
          transformOrigin: '50% -50%',
          transform: [{ rotateX: !dropdownShown ? '-15deg' : '0deg' }],
          position: 'absolute',
          top: '100%',
          // left,
          // left: '-50%',
          transitionProperty: 'transform, opacity',
          willChange: 'transform, opacity',
          opacity: !dropdownShown ? 0 : 1,
          transitionDuration: '250ms',
          right: -80,
        }}
      >
        <View>{children}</View>
      </View>
    </Pressable>
  )
}

export { Dropdown, Trigger, Popper, useMenuController }
